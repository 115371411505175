import { ConfigProvider, Select } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHome from "utils/home";

export default function ClientDropDown() {
  const { t } = useTranslation();

  const { handleLogOut } = useHome();
  const items = [
    {
      key: "1",
      label: (
        <NavLink to="/client/workers-list">
          {t("home.Professional Listing")}
        </NavLink>
      ),
      value: "listing",
    },
    {
      key: "2",
      label: <NavLink to="client/message">{t("home.Messages")}</NavLink>,
      value: "messages",
    },
    {
      key: "3",
      label: <NavLink to="client/dashboard">{t("home.Dashboard")}</NavLink>,
      value: "dashboard",
    },

    {
      key: "4",
      label: <p onClick={() => handleLogOut()}>{t("home.Log Out")}</p>,
      value: "log out",
    },
  ];

  return (
    <>
    <ConfigProvider
    theme={{
      token: {
        borderRadius: 5,
        controlHeight: 25,
        colorBorder: "rgb(96, 51, 29)",
      },
    }}
    >
    <Select
        placeholder={t("home.Client")}
        className="selector_inputs"
        size="large"
        showArrow
        options={items?.map((el) => ({
          label: el?.label,
          value: el?.value,
        }))}
      />
  </ConfigProvider>
    </>
  );
}
