import { useTranslation } from "react-i18next";
import { AiFillContacts, AiOutlineFileSearch } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BsFillBagFill } from "react-icons/bs";
import { CheckOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { checkAuth } from "redux/authActions";
import { auth } from "config";
import { message } from "antd";

export default function useHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    await signOut(auth)
      .then(() => {
        dispatch(checkAuth(null));
        localStorage.clear();
        navigate("/login");
        message?.success("you are log out now");
      })
      .catch((error) => {
        alert(error?.message);
      });
  };
  const { t } = useTranslation();

  const projectHome = [
    {
      id: 1,
      title: t("home.Post your job"),
      description: t(
        "home.Post your job in a few simple steps and invite interesting professional to respond."
      ),
    },
    {
      id: 2,
      title: t("home.Professionals respond"),
      description: t(
        "home.Interested professionals usually respond with 24 hours of posting your job."
      ),
    },
    {
      id: 3,
      title: t("home.Select and start a conversation"),
      description: t(
        "home.Compare profiles and add your favorite professionals to your selection to discuss your job and request a quote."
      ),
    },
  ];

  const worksItems = [
    {
      id: 1,
      title: t("home.Find Best Talent"),
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem dolorum accusantium perferendis numquam dolore impedit id iste officia hic nemo",
      icon: (
        <HiUserGroup
          style={{
            fontSize: "50px",
          }}
        />
      ),
    },
    {
      id: 2,
      title: t("home.Search for work"),
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem dolorum accusantium perferendis numquam dolore impedit id iste officia hic nemo,",
      icon: (
        <AiOutlineFileSearch
          style={{
            fontSize: "50px",
          }}
        />
      ),
    },
    {
      id: 3,
      title: t("home.Post a job (its free)"),
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem dolorum accusantium perferendis numquam dolore impedit id iste officia hic nemo,",
      icon: (
        <BsFillBagFill
          style={{
            fontSize: "50px",
          }}
        />
      ),
    },
    {
      id: 4,
      title: t("home.Contact best Professionals"),
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem dolorum accusantium perferendis numquam dolore impedit id iste officia hic nemo,",
      icon: (
        <AiFillContacts
          style={{
            fontSize: "50px",
          }}
        />
      ),
    },
  ];
  const footerItems = [
    {
      id: 1,
      title: <NavLink to="/contactUs">{t("home.footer.Contact Us")}</NavLink>,
    },
    {
      id: 2,
      title: (
        <NavLink to="/termsAndConditions">
          {t("home.footer.Terms And Conditions")}
        </NavLink>
      ),
    },
    {
      id: 4,
      title: (
        <NavLink to="/privacyPolicy">{t("home.footer.Privacy Policy")}</NavLink>
      ),
    },
  ];
  const footerItem = [
    {
      id: 3,
      title: <NavLink to="/howitworks">{t("home.How it works")}</NavLink>,
    },
    {
      id: 1,
      title: (
        <NavLink to="/postajob">{t("home.footer.Hire Professionals")}</NavLink>
      ),
    },
    {
      id: 2,
      title: <NavLink to="/postajob">{t("home.footer.Post Jobs")}</NavLink>,
    },
  ];
  const subscription_Headings = [
    {
      id: 1,
      title: t("No confusing cost structure"),
    },
    {
      id: 2,
      title: t("One simple plan for all of your needs"),
    },
    {
      id: 3,
      title: t("Cancel at anytime, no annual tie-in"),
    },
  ];
  const PACKAGE_HEADINGS = [
    {
      id: 1,
      title: "Professionally maintained profile",
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 2,
      title: "Register yourself under all your categories",
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 3,
      title: "Access to customer support",
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 4,
      title: "Unlimited contact with prospective clients",
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 5,
      title: "Receive payment via secure Stripe format",
      icon: <CheckOutlined className="outlined-color" />,
    },
  ];
  const PREMIUM_HEADINGS = [
    {
      id: 1,
      title: t("home.Make profile on Goosefix"),
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 2,
      title: t("home.Contacts with clients"),
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 3,
      title: t("home.Get Orders"),
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 4,
      title: t("home.Get Payment"),
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 5,
      title: t("home.Get Blue Tick"),
      icon: <CheckOutlined className="outlined-color" />,
    },
    {
      id: 6,
      title: t("home.Get Extra Functionalities"),
      icon: <CheckOutlined className="outlined-color" />,
    },
  ];
  const professionalRoutes = [
    {
      key: "1",
      label: (
        <NavLink to="/professional/user-profile">
          {t("home.User Profile")}
        </NavLink>
      ),
    },
    {
      key: "2",
      label: <NavLink to="professional/message">{t("home.Messages")}</NavLink>,
    },
    {
      key: "3",
      label: (
        <NavLink to="/professional/jobslist">{t("home.Dashboard")}</NavLink>
      ),
    },
    {
      key: "4",
      label: (
        <NavLink to="/professional/payments">
          {t("home.Payment Details")}
        </NavLink>
      ),
    },
    {
      key: "5",
      label: <NavLink to="/subscription">{t("home.Subscription")}</NavLink>,
    },
    {
      key: "6",
      label: <p>{t("home.Log Out")}</p>,
    },
  ];
  const items = [
    {
      key: "completed",
      label: t("home.Completed"),
    },
    {
      key: "inProgress",
      label: t("home.In Progress"),
    },
    {
      key: "cancelled",
      label: t("home.Cancelled"),
    },
    {
      key: "pending",
      label: t("home.Pending"),
    },
  ];
  const supportQuestions = [
    {
      Id: 1,
      text: t("support.I would like to become a member. Can I talk to someone about this?"),
      answer: t("support.answer1"),
    },
    {
      Id: 2,
      text: t("support.How do I register as a professional with GooseFix?"),
      answer: t("support.answer2"),
    },
    {
      Id: 3,
      text: t("support.What information is required to register with GooseFix?"),
      answer: t("support.answer3"),
    },
    {
      Id: 4,
      text: t("support.How does GooseFix work?"),
      answer: t("support.answer4"),
    },
    {
      Id: 5,
      text: t("support.Can I register without any obligations?"),
      answer: t("support.answer5"),
    },
    {
      Id: 6,
      text: t("support.Why do I need a Chamber of Commerce number for my registration?"),
      answer: t("support.answer6"),
    },
    {
      Id: 7,
      text: t("support.What kind of assignments can I find on GooseFix?"),
      answer: t("support.answer7"),
    },
    {
      Id: 8,
      text: t("support.What are the costs?"),
      answer: t("support.answer8"),
    },
  ];

  return {
    projectHome,
    worksItems,
    footerItems,
    footerItem,
    subscription_Headings,
    PACKAGE_HEADINGS,
    PREMIUM_HEADINGS,
    professionalRoutes,
    items,
    handleLogOut,
    supportQuestions
  };
}
