import { Divider, Empty, Typography } from "antd";
import useNotification from "hooks/useNotification";
import { RxDotFilled } from "react-icons/rx";
import { updateNotificationDetails } from "services/users";

const { Text } = Typography;
export default function Notification() {
  // eslint-disable-next-line
  const { loading, data } = useNotification("notifications");

  return (
    <div className="notification_main">
      {data?.length > 0 ? (
        <>
          {data?.map((el, index) => {
            return (
              <>
                <div
                  className="notifications-main"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => updateNotificationDetails(`${el?.id}`)}
                >
                  <b>{el?.projectTitle}</b>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text>{el?.notification}</Text>
                    {el?.isRead === false && (
                      <>
                        <RxDotFilled
                          style={{ color: "green", fontSize: "30px" }}
                        />
                      </>
                    )}
                  </div>
                  <Divider />
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <Empty />
        </>
      )}
    </div>
  );
}
