import { createSlice } from "@reduxjs/toolkit";
import { checkAuth, registerUser, userLogin } from "./authActions";

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
  messageId: null
};

const authSlice = createSlice({
  name: "authUsers",
  initialState,
  reducers: {
    getMessageId: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [checkAuth.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [checkAuth.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [checkAuth.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { getMessageId } = authSlice.actions;
export default authSlice.reducer;
