import { Button } from 'antd'
import SmallSpinner from 'components/common/smallSpinner'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { registerUser } from 'redux/authActions'
import { FaArrowCircleRight } from "react-icons/fa";

export default function Completion() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const price = 25;
  const subscriptionUserInfo = JSON.parse(localStorage.getItem('userInfo'))


  const handleNavigationProfile = () => {
    setLoading(true)
    try {
      if (subscriptionUserInfo) {
        dispatch(
          registerUser({
            username: subscriptionUserInfo?.username,
            email: subscriptionUserInfo?.email,
            password: subscriptionUserInfo?.password,
            role: subscriptionUserInfo.role,
            navigate,
            price,
          })
        )
      }
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div style={{
      width: '100%',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

    }}>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '50%',
        background: "white",
        padding: '20px',
        borderRadius: '20px',
      }}>
        <h2 style={{ textAlign: 'center' }}>Congratulations ! your payment is successfull. </h2>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: "10px",
        }}>
          <FaArrowCircleRight style={{ fontSize: '22px' }} />
          <Button
            onClick={() => {
              handleNavigationProfile()
            }}>
            {loading ? <SmallSpinner /> : "Go to Your Profile"}
          </Button>
        </div>

      </div>
    </div >
  )
}
