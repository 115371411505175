import { doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "config";
import { ROLES } from "utils/constants";
import { updateSubscription } from "./helpers";
import { message } from "antd";

/**
 * create a user in firebase, upload user file and add data to firestore
 * @param {String} name
 * @param {String} email
 * @param {String} password
 * @param {Object} file
 */
// let date = new Date().toJSON().slice(0, 10);
const usersData = async (username, email, password, userId, role, price) => {
  return new Promise((resolve, reject) => {
    const userRef = doc(db, "users", userId);
    const docData = {
      uid: userId,
      email,
      username,
      password,
      createdAt: serverTimestamp(),
      docRefId: userRef.id,
      role: role,
    };
    setDoc(userRef, docData).then(async () => {
      message?.success("Your account is registered")
      resolve()
      if (role === ROLES?.PROFESSIONAL) {
        await updateSubscription("users", userId, price)
      }
    }).catch(() => {
      reject()
    });
  })

};

export { usersData };

export const updateNotificationDetails = async (id) => {
  const docRef = doc(db, "notifications", `${id}`);
  await updateDoc(docRef, {
    isRead: true,
  })
    .then((res) => { })
    .catch((error) => {
      console.log(error);
    });
};

