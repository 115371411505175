import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Select } from "antd";
import useHome from "utils/home";

export default function ProfessionalDropdown() {
  const { handleLogOut } = useHome();
  const { t } = useTranslation();
  const items = [
    {
      key: "1",
      label: (
        <NavLink to="/professional/user-profile">
          {t("home.User Profile")}
        </NavLink>
      ),
    },
    {
      key: "2",
      label: <NavLink to="professional/message">{t("home.Messages")}</NavLink>,
    },
    {
      key: "3",
      label: (
        <NavLink to="/professional/jobslist">{t("home.Dashboard")}</NavLink>
      ),
    },
    {
      key: "4",
      label: <p onClick={() => handleLogOut()}>{t("home.Log Out")}</p>,
    },
  ];
  return (
    <>
    <ConfigProvider
    theme={{
      token: {
        borderRadius: 5,
        controlHeight: 25,
        colorBorder: "rgb(96, 51, 29)",
      },
    }}
    >
      <Select
        placeholder={t("home.Professional")}
        size="large"
        className="selector_inputs"
        showArrow
        options={items?.map((el) => ({
          label: el?.label,
          value: el?.value,
        }))}
      />
      </ConfigProvider>
    </>
  );
}
