import { Button, Drawer, Menu } from "antd";
import React, { useState } from "react";
import { auth } from "config";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { info } from "sass";
import { checkAuth } from "redux/authActions";
import { useDispatch } from "react-redux";
import useHome from "utils/home";
import { signOut } from "firebase/auth";
import { ROLES } from "utils/constants";

const ResponsiveDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { professionalRoutes, clientsRoutes } = useHome();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleItemClick = (item, index) => {
    setSelectedItem(item?.label?.props?.children);
    if (item?.label?.props?.children === "Log Out") {
      signOut(auth)
        .then(() => {
          dispatch(checkAuth(null));
          localStorage.clear();
          navigate("/login");
        })
        .catch((error) => {
          alert(error?.message);
        });
    }
  };
  return (
    <div className="navbar_drawer">
      <Button className="drawer-btn" onClick={showDrawer}>
        <AiOutlineMenu />
      </Button>
      <Drawer
        title="Goosefix"
        placement="right"
        onClose={onClose}
        open={open}
        className="drawer"
        width={220}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={() => navigate("/howitworks")}
            >
              {t("common.How Does It Works?")}
            </Button>
          </div>
          <Button
            style={{ fontWeight: "bold", marginTop: "10px" }}
            onClick={() => navigate("/jobs")}
          >
            {t("common.Post a job")}
          </Button>
          <div>
            {info?.role === ROLES?.CLIENT ? (
              <div>
                {clientsRoutes.map((item, index) => (
                  <Menu.Item
                    key={index}
                    onClick={() => handleItemClick(item, index)}
                  >
                    {item?.label}
                  </Menu.Item>
                ))}
              </div>
            ) : (
              <Menu>
                {professionalRoutes?.map((item, index) => (
                  <Menu.Item
                    key={index}
                    onClick={() => handleItemClick(item, index)}
                  >
                    {item?.label}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </div>
          {!info && (
            <>
              <Button
                type="primary"
                style={{ margin: "10px", width: "150px" }}
                onClick={() => navigate("/login")}
              >
                {t("common.Login")}
              </Button>
              <Button
                type="primary"
                style={{ margin: "10px", width: "150px" }}
                onClick={() => navigate("/register")}
              >
                {t("common.Register")}
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default ResponsiveDrawer;
