import { Avatar, Button, Divider, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
// import useAll from "hooks/useGetAllData";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { CiLight } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
// import { jobTypesObj } from "utils/constants";
import ProfileConstants from "utils/profileConstants";

const { Text } = Typography;
export default function TopCategories() {
  const { jobTypes } = ProfileConstants();
  // const { data } = useAll("themeImages");
  const { t } = useTranslation();
  const filterValues = ["carpenter", "kitchens", "electrician"];
  // const filterImages = data?.filter(job => filterValues.includes(job.title));
  const filteredJobTypes = jobTypes?.filter((job) =>
    filterValues.includes(job.value)
  );

  // Find matching item in array1 based on title and value
  // const matchingItem = filterImages && filterImages?.find(item => item.title === filteredJobTypes.value);

  // If there's a match, update the img field
  // if (matchingItem) {
  //   filteredJobTypes.img = matchingItem?.image;
  // }

  // Output the updated second array
  const navigate = useNavigate();
  const [ellipsis, setEllipsis] = useState(true);
  return (
    <div className="categories">
      <div className="hiring">
        <div className="main-hiring">
          <Typography className="title1">{t("home.Top categories")}</Typography>
          <div className="hiring_three">
            {filteredJobTypes?.map((item) => {
              return (
                <>
                  <div className="hiring_inner" key={item?.id}>
                    <div className="first_hiring">
                      <div className="map_2">
                        <img
                          src={item?.img}
                          width="100%"
                          height="200px"
                          style={{ borderRadius: "10px" }}
                          alt=""
                        />
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Avatar shape="square" src={item?.icon} />
                          <Typography className="title3">
                            {item?.label}
                          </Typography>
                        </div>
                        <Paragraph
                          ellipsis={
                            ellipsis
                              ? {
                                  rows: 2,
                                  expandable: true,
                                  symbol: "more",
                                }
                              : false
                          }
                          className="text3"
                        >
                          {item?.description}
                        </Paragraph>
                        <Divider />
                        <Button
                          type="link"
                          onClick={() => {
                            navigate(`/viewDetailsJob?jobType=${item?.value}`, {
                              state: {
                                label: item?.label,
                                detail: item?.detail,
                                children: item?.children,
                              },
                            });
                          }}
                        >
                          <p style={{ textDecoration: "underline" }}>
                            {t("home.All information about")} {item?.label}
                          </p>
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
