import { lazy, Suspense } from "react";
import Spinner from "components/common/spinner";


const LazyLoader = ({ path }) => {
  // const LazyComponent = lazy(() => import(`/pages/${path}`));

 const LazyComponent= lazy(() => import(`../pages/${path}`));
  return (
    <Suspense fallback={<Spinner />}>
      <LazyComponent />
    </Suspense>
  );
}

export default LazyLoader;
