import { Button, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Listing from "./listing";
import HomeFooter from "./homeFooter";
import "aos/dist/aos.css";
import Header from "components/layout/header";
import Footer from "components/layout/footer";
import { useTranslation } from "react-i18next";
import useHome from "utils/home";
import SearchMain from "./searchMain";
import FindProfessional from "./findProfessional";
import TopCategories from "./topCategories";
import SomeJobs from "./someJobs";
import Spinner from "components/common/spinner";

const { Title, Text } = Typography;
function HomeScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectHome } = useHome();
  const [loaded, Isloaded] = useState(false);

  const handelOnloaded = () => {
    Isloaded(false);
  };

  return (
    <>
      <div className="main_home">
        <div>
          <Header />
        </div>
        <div className="main_home_img" onLoad={handelOnloaded}>
          <div className="main_img_div">
            {loaded && <Spinner />}
            {!loaded && (
              <div className="main_text">
                <div className="home_text">
                  <Title className="title">
                    {t(
                      "home.Find a professional for all your jobs in and around the house"
                    )}
                  </Title>
                  <Text className="text_Home">
                    {t("home.What is your job?")}
                  </Text>
                  <SearchMain />
                  <Button
                    type="primary"
                    className="btn_home"
                    size="large"
                    onClick={() => navigate("./login")}
                  >
                    {t("home.Get Started")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="main_home_inner">
          <div className="hiring">
            <div className="main-hiring">
              <Typography className="title1">
                {t("home.How does Goosefix work?")}
              </Typography>
              <div className="hiring_three">
                {projectHome?.map((item) => {
                  return (
                    <>
                      <div className="hiring_inner" key={item?.id}>
                        <div className="first_hiring">
                          <div className="map_1">
                            <Typography
                              className="title3"
                              style={{ color: "#e5cb4e" }}
                            >
                              {item?.id}
                            </Typography>
                          </div>
                          <div className="map_2">
                            <Typography className="title3">
                              {item?.title}
                            </Typography>
                            <Text className="text3">{item?.description}</Text>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <SomeJobs />
        <TopCategories />
        <FindProfessional />
        <Listing />
        <HomeFooter />
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default HomeScreen;
