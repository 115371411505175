

export const OPERATORS = {
  EQUAL: "==",
};

export const subscriptionRate = {
  full_year: 150,
  half_year: 100,
  one_month: 25,
};

export const subscriptions = [
  { id: 1, type: "Standard", label: "Free", price: 0 },
  { id: 2, type: "premium", label: "Premium", price: 100 },
];

export const projectStatus = [
  {
    id: 1,
    title: "Completed",
  },
  {
    id: 2,
    title: "In Progress",
  },
  {
    id: 3,
    title: "Pending",
  },
  {
    id: 4,
    title: "Cancelled",
  },
];
export const Languages = [
  {
    id: 1,
    label: "English",
    value: "English",
  },
  {
    id: 2,
    label: "Dutch",
    value: "Dutch",
  },
];
const LOCALURL = "http://localhost:8000"
const BACKEND_URL = process.env.REACT_APP_VERCEL_URL
const PRICE_ID = process.env.REACT_APP_ONE_MONTH_SUBSCRIPTION
export const PAGE_LIMIT = 10;
export const END_POINT = BACKEND_URL;
export const Google_Map_Api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY
export const YEARLY_SUBSCRIPTION = "price_1OCGSnJRJo4WtzAn5w5EYLIc";
export const HALF_YEAR_SUBSCRIPTION = "price_1OCGRoJRJo4WtzAnIHLYVByF";
export const ONE_MONTH_SUBSCRIPTION = PRICE_ID;
// paid id
// export const ONE_MONTH_SUBSCRIPTION = "price_1OdZw5A30Srq17lgl4Yv94F3"
//new id 
// export const ONE_MONTH_SUBSCRIPTION = "price_1OjKCeA30Srq17lgmjH3LauU"
// new id for one euro
// export const ONE_MONTH_SUBSCRIPTION = "price_1OjL6mA30Srq17lgi9rtjy48"

export const jobTypesObj = {
  plastering: "Plasters",
  plumbing: "Plumber",
  roofs: "Roofs",
  generalJobs: "General Jobs",
  bathroomSpecialists: "Bathroom Specialists",
  Electrician: "Electrician",
};
export const projectStatusObj = {
  inProgress: "In Progress",
  completed: "Completed",
};

export const ROLES = {
  PROFESSIONAL: "professional",
  CLIENT: "client",
};
