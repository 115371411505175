import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "config";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "redux/authActions";
import Routes from "./routes";
import Spinner from "components/common/spinner";
import { Google_Map_Api_key } from "utils/constants";


function App() {
  const { loading } = useSelector((state) => state.authUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(
          checkAuth({
            role: user.displayName,
            userId: user.uid,
            email: user.email,
          })
        );
      } else {
        dispatch(checkAuth(null));
      }
    }); // eslint-disable-next-line
  }, []);
  console.log(Google_Map_Api_key, 'Google_Map_Api_key in App. js')

  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", Google_Map_Api_key);
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [Google_Map_Api_key]);

  return loading ? <Spinner /> : <Routes />;
}

export default App;
