import { Avatar, Button, Typography, Carousel, Rate, Empty } from "antd";
import { generatePath, useNavigate } from "react-router-dom";
import img from "../asset/userIcon.jpeg";
import useHomeListing from "hooks/useHomeListing";
import Spinner from "components/common/spinner";
import blueTick from "../asset/blueTick.png";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const Listing = () => {
  const { t } = useTranslation();
  const { data } = useHomeListing("users");
  const slides = [];
  for (let i = 0; i < data?.length; i += 3) {
    const chunk = data?.slice(i, i + 3);
    slides.push(chunk);
  }
  const navigate = useNavigate();
  if (!data) return <Spinner />;
  return (
    <>
      <div className="main_listing">
        <div className="listing_inner">
          <Title className="title3">
            {t("home.Find  professional talent to take on any project")}
          </Title>
          {slides.length > 0 ? (
            <>
              <Carousel easing="linear" className="main_carousel">
                {slides.map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {item.map((el, index) => {
                          return (
                            <>
                              <div key={el?.id}>
                                <div className="workers_box">
                                  <div className="workers_inner_box">
                                    <Avatar
                                      src={el?.photoURL ?? img}
                                      size={120}
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    />
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        navigate(
                                          generatePath(
                                            `/client/workers-list/${el?.uid}`
                                          )
                                        );
                                      }}
                                    >
                                      <Typography.Title
                                        level={3}
                                        className="Title"
                                      >
                                        {el?.username}
                                        {el?.isSubscription === true && (
                                          <>
                                            <Avatar
                                              src={blueTick}
                                              size={20}
                                              style={{
                                                verticalAlign: "middle",
                                                marginTop: "-5px",
                                              }}
                                            />
                                          </>
                                        )}
                                      </Typography.Title>
                                    </Button>
                                    <Typography className="text">
                                      {el?.headline ?? "Not added Headline"}{" "}
                                    </Typography>
                                    <div>
                                      {el?.averageRating ? (
                                        <>
                                          <Rate
                                            disabled
                                            allowHalf
                                            value={el?.averageRating}
                                          />
                                        </>
                                      ) : (
                                        <>New On Goosefix</>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </Carousel>
            </>
          ) : (
            <>
              <Empty />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Listing;
