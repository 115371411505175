import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyCK-Yx4NntIGOqeehbEO4vzVQXC2vbwu2s",
  authDomain: "goosefix-ff2f5.firebaseapp.com",
  projectId: "goosefix-ff2f5",
  storageBucket: "goosefix-ff2f5.appspot.com",
  messagingSenderId: "957485570493",
  appId: "1:957485570493:web:f303eda008ab27d9751ad4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase();
export { db, auth, storage, database };
