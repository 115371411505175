import { RiAlarmWarningLine } from "react-icons/ri";
import { TbCarGarage } from "react-icons/tb";
import { RiLandscapeFill } from "react-icons/ri";
import { MdOutlineSatelliteAlt } from "react-icons/md";
import { FcSurvey } from "react-icons/fc";
import { GiGardeningShears } from "react-icons/gi";
import { GiHotSurface } from "react-icons/gi";
import { IoRemoveCircleSharp } from "react-icons/io5";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { AiFillFormatPainter } from "react-icons/ai";
import { SiIledefrancemobilites } from "react-icons/si";
import { PiPath } from "react-icons/pi";
import { RiFlightLandLine } from "react-icons/ri";
// import { GiFootPlaster } from "react-icons/gi";
import { SiElectronbuilder } from "react-icons/si";
// import { MdOutlinePlumbing } from "react-icons/md";
import { SiTelegraph } from "react-icons/si";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { SlEnergy } from "react-icons/sl";
import { FaBath } from "react-icons/fa6";
import { GiTheaterCurtains } from "react-icons/gi";
import { MdCleaningServices } from "react-icons/md";
import { GiLaptop } from "react-icons/gi";
import { BsBricks } from "react-icons/bs";
import { GiBlacksmith } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import { GiNuclearWaste } from "react-icons/gi";
import { GiRedCarpet } from "react-icons/gi";
import { GiOpenGate } from "react-icons/gi";
import { GiHeatHaze } from "react-icons/gi";
import { GiDominoTiles } from "react-icons/gi";
import { FaScreenpal } from "react-icons/fa6";
import { MdEmojiTransportation } from "react-icons/md";
import { BsDoorOpenFill } from "react-icons/bs";
// import { MdRoofing } from "react-icons/md";
import { FaBed } from "react-icons/fa6";
import { TbLockSearch } from "react-icons/tb";
import { GiDeliveryDrone } from "react-icons/gi";
import { PiGarageLight } from "react-icons/pi";
import { MdAmpStories } from "react-icons/md";
import { GiBonsaiTree } from "react-icons/gi";
import { GiChimney } from "react-icons/gi";
import { DiRasberryPi } from "react-icons/di";
import { FaHouseFloodWater } from "react-icons/fa6";
import { LiaHireAHelper } from "react-icons/lia";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { GiRuneStone } from "react-icons/gi";
import { SiPyscaffold } from "react-icons/si";
import { MdFireplace } from "react-icons/md";
import { AiFillInsurance } from "react-icons/ai";
import { TbPoolOff } from "react-icons/tb";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { FaHourglassHalf } from "react-icons/fa6";
import { MdOutlinePool } from "react-icons/md";
import { PiMotorcycleBold } from "react-icons/pi";
import { SiCinema4D } from "react-icons/si";
import { TbAirConditioning } from "react-icons/tb";
import { MdPestControl } from "react-icons/md";
import { MdOutlineArchitecture } from "react-icons/md";
import { FaCampground } from "react-icons/fa";
import { GiSofa } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { images } from "components/asset";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config";
import { message } from "antd";
import { useState } from "react";

export default function ProfileConstants() {
  const { t } = useTranslation();
  const [loadingUpdating, setLoadingUpdating] = useState(false);
  const updateDocument = async (
    path,
    id,
    username,
    email,
    phoneNumber,
    headline,
    address,
    postalCode,
    services,
    about,
    addressObject,
    servicesStrings
  ) => {
    console.log(path,
      id,
      username,
      email,
      phoneNumber,
      headline,
      address,
      postalCode,
      services,
      about,
      addressObject,
      servicesStrings,
      "In put"
    )
    setLoadingUpdating(true);
    const docRef = doc(db, path, id);
    await updateDoc(docRef, {
      username: username,
      email: email,
      phoneNumber: phoneNumber,
      headline: headline,
      address: address ? address : "",
      services: services,
      about: about,
      postalCode: postalCode,
      addressObject: addressObject,
      servicesStrings: servicesStrings,
    })
      .then(() => {
        setLoadingUpdating(false);
        message?.success("your profile is updated !");
      })
      .catch((error) => {
        alert(error?.message);
        setLoadingUpdating(false);
      });
  };

  const jobTypes = [
    {
      id: 1,
      label: t("jobTypes.Alarms / Security"),
      value: "alarmsSecurity",
      detail:
        "Effective alarms and security details are crucial components in safeguarding people and property. Alarms, ranging from traditional sirens to advanced smart systems, serve as early warning systems, deterring intruders and notifying authorities. Security details, comprised of trained personnel and surveillance technologies, provide a layered defense against potential threats.",
      icon: <RiAlarmWarningLine />,
      children: [
        {
          label: t("jobTypes.CCTV Repair"),
          value: "cctvRepair",
        },
        {
          label: t("jobTypes.Gate Automation"),
          value: "gateAutomation",
        },
        {
          label: t("jobTypes.Scaffold Alarms"),
          value: "scaffoldAlarms",
        },
        {
          label: t("jobTypes.Fire Alarm Installation"),
          value: "fireAlarmInstallation",
        },
        {
          label: t("jobTypes.Burglar Alarm - Supply & Installation"),
          value: "burglarAlarmSupplyInstallation",
        },
        {
          label: t("jobTypes.Alarm Response"),
          value: "alarmResponse",
        },
        {
          label: t("jobTypes.CCTV - Supply Only"),
          value: "cctvSupplyOnly",
        },
        {
          label: t("jobTypes.Burglar Alarm - Supply Only"),
          value: "burglarAlarmSupplyOnly",
        },
        {
          label: t("jobTypes.Burglar Alarm - Installation"),
          value: "burglarAlarmInstallation",
        },
        {
          label: t("jobTypes.Intruder Alarms"),
          value: "intruderAlarms",
        },
        {
          label: t("jobTypes.Industrial Doors"),
          value: "industrialDoors",
        },
        {
          label: t("jobTypes.Electric Gate Installation"),
          value: "electricGateInstallation",
        },
        {
          label: t("jobTypes.Security Assessment"),
          value: "securityAssessment",
        },
        {
          label: t("jobTypes.Safes"),
          value: "safes",
        },
        {
          label: t("jobTypes.Fire Extinguishers"),
          value: "fireExtinguishers",
        },
        {
          label: t("jobTypes.Security Solutions / Access Control"),
          value: "securitySolutionsAccessControl",
        },
        {
          label: t("jobTypes.Intercom Entry Systems"),
          value: "intercomEntrySystems",
        },
        {
          label: t("jobTypes.Call / Door Entry Systems"),
          value: "callDoorEntrySystems",
        },
        {
          label: t("jobTypes.Biometric Security Systems"),
          value: "biometricSecuritySystems",
        },
      ],
    },
    {
      id: 2,
      label: t("jobTypes.Garage / Vehicle Services"),
      value: "garageVehicleServices",
      detail:
        "Garage and vehicle services encompass a range of automotive maintenance and repair offerings. These facilities provide routine services such as oil changes, tire rotations, and brake inspections to ensure optimal vehicle performance. Additionally, they specialize in diagnosing and fixing mechanical issues, conducting engine repairs, and performing comprehensive vehicle inspections.",
      icon: <TbCarGarage />,
      children: [
        {
          label: t("jobTypes.Car Audio"),
          value: "carAudio",
        },
        {
          label: t("jobTypes.Breakdown Assistance"),
          value: "breakdownAssistance",
        },
        {
          label: t("jobTypes.Car Alarms"),
          value: "carAlarms",
        },
        {
          label: t("jobTypes.Accident Repair"),
          value: "accidentRepair",
        },
        {
          label: t("jobTypes.Emergency Garage / Vehicle Service"),
          value: "emergencyGarageVehicleService",
        },
        {
          label: t("jobTypes.Motor checks"),
          value: "motorChecks",
        },
        {
          label: t("jobTypes.Mobile Mechanic"),
          value: "mobileMechanic",
        },
        {
          label: t("jobTypes.Car Repair"),
          value: "carRepair",
        },
        {
          label: t("jobTypes.Smart Repairs"),
          value: "smartRepairs",
        },
        {
          label: t("jobTypes.Motorcycle checks"),
          value: "motorcycleChecks",
        },
        {
          label: t("jobTypes.Mechanic"),
          value: "mechanic",
        },
        {
          label: t("jobTypes.Locking Wheel Nut Removal"),
          value: "lockingWheelNutRemoval",
        },
        {
          label: t("jobTypes.Engine Cleaning"),
          value: "engineCleaning",
        },
        {
          label: t("jobTypes.Van Conversions"),
          value: "vanConversions",
        },
        {
          label: t("jobTypes.Vehicle Paint Spraying"),
          value: "vehiclePaintSpraying",
        },
        {
          label: t("jobTypes.Tyre Fitting"),
          value: "tyreFitting",
        },
        {
          label: t("jobTypes.Battery / Exhaust / Tyres"),
          value: "batteryExhaustTyres",
        },
        {
          label: t("jobTypes.Caravan Valeting"),
          value: "caravanValeting",
        },
        {
          label: t("jobTypes.Vehicle / Car Valeting"),
          value: "vehicleCarValeting",
        },
        {
          label: t("jobTypes.Vehicle Wrapping"),
          value: "vehicleWrapping",
        },
        {
          label: t("jobTypes.Vehicle Signwriting"),
          value: "vehicleSignwriting",
        },
        {
          label: t("jobTypes.Vehicle Air Conditioning Servicing / Repair"),
          value: "vehicleAirConditioningServicingRepair",
        },
        {
          label: t("jobTypes.Vehicle Window Tinting"),
          value: "vehicleWindowTinting",
        },
        {
          label: t("jobTypes.Gearbox"),
          value: "gearbox",
        },
        {
          label: t("jobTypes.Vehicle / Car Body Repair"),
          value: "vehicleCarBodyRepair",
        },
        {
          label: t("jobTypes.Clutches"),
          value: "clutches",
        },
        {
          label: t("jobTypes.Car / Auto Electrics"),
          value: "carAutoElectrics",
        },
        {
          label: t("jobTypes.Scooter Checks"),
          value: "scooterChecks",
        },
        {
          label: t("jobTypes.Dent Repair"),
          value: "dentRepair",
        },
        {
          label: t("jobTypes.Motorcycle Tyres"),
          value: "motorcycleTyres",
        },
        {
          label: t("jobTypes.Car Sales / Commercial Sales"),
          value: "carSalesCommercialSales",
        },
      ],
    },
    {
      id: 3,
      label: t("jobTypes.Landscaper"),
      value: "landscaper",
      detail:
        "Landscapers are skilled professionals who transform outdoor spaces into aesthetically pleasing and functional environments. With expertise in horticulture, design, and construction, they collaborate with clients to create personalized landscapes. Landscapers assess soil conditions, select appropriate plants, and design layouts that enhance the natural beauty of a site",
      icon: <RiLandscapeFill />,
      children: [
        {
          label: t("jobTypes.Garden Design"),
          value: "gardenDesign",
        },
        {
          label: t("jobTypes.Decking"),
          value: "decking",
        },
        {
          label: t("jobTypes.Play Areas"),
          value: "playAreas",
        },
        {
          label: t("jobTypes.Artificial Grass"),
          value: "artificialGrass",
        },
        {
          label: t("jobTypes.Aviaries"),
          value: "aviaries",
        },
        {
          label: t("jobTypes.Shed Bases"),
          value: "shedBases",
        },
        {
          label: t("jobTypes.Landscape Contractor"),
          value: "landscapeContractor",
        },
        {
          label: t("jobTypes.Pond Construction"),
          value: "pondConstruction",
        },
        {
          label: t("jobTypes.Golf / Bowls Greens"),
          value: "golfBowlsGreens",
        },
        {
          label: t("jobTypes.Hard Landscaping"),
          value: "hardLandscaping",
        },
        {
          label: t("jobTypes.Bricklayer"),
          value: "bricklayer",
        },
        {
          label: t("jobTypes.Garden Rooms / Annexes"),
          value: "gardenRoomsAnnexes",
        },
        {
          label: t("jobTypes.Grounds Maintenance"),
          value: "groundsMaintenance",
        },
        {
          label: t("jobTypes.Sheds"),
          value: "sheds",
        },
        {
          label: t("jobTypes.Sensory Gardens"),
          value: "sensoryGardens",
        },
        {
          label: t("jobTypes.Sleepers"),
          value: "sleepers",
        },
        {
          label: t("jobTypes.Irrigation Systems"),
          value: "irrigationSystems",
        },
      ],
    },
    {
      id: 4,
      label: t("jobTypes.TV Aerials / Satellite Services"),
      value: "tvAerialsSatelliteServices",
      detail:
        "TV Aerials and Satellite Services involve installing, repairing, and maintaining television reception equipment. Aerial installation ensures optimal broadcast signal reception, while satellite services provide access to a variety of channels via satellite dishes. Professionals align and position antennas for optimal signal strength, troubleshooting issues such as pixelation or signal loss.",
      icon: <MdOutlineSatelliteAlt />,
      children: [
        {
          label: t("jobTypes.TV Aerial / Satellite Installation"),
          value: "tvAerialSatelliteInstallation",
        },
        {
          label: t("jobTypes.Communal Aerial / Satellites"),
          value: "communalAerialSatellites",
        },
        {
          label: t("jobTypes.Aerial Repair"),
          value: "aerialRepair",
        },
        {
          label: t("jobTypes.Satellite Broadband Services"),
          value: "satelliteBroadbandServices",
        },
        {
          label: t("jobTypes.Network Cabling"),
          value: "networkCabling",
        },
        {
          label: t("jobTypes.TV Repair"),
          value: "tvRepair",
        },
        {
          label: t("jobTypes.Video / Audio Repair"),
          value: "videoAudioRepair",
        },
      ],
    },
    {
      id: 5,
      label: t("jobTypes.Surveying"),
      value: "surveying",
      detail:
        "Surveying is a multidisciplinary practice involving the measurement and mapping of land, determining boundaries, and assessing topography. Utilizing advanced instruments like total stations and GPS, surveyors collect precise data to create accurate maps and plans. This information aids in urban planning, construction projects, and land development.",
      icon: <FcSurvey />,
      children: [
        {
          label: t("jobTypes.Land Appraisal"),
          value: "landAppraisal",
        },
        {
          label: t("jobTypes.Quantity Surveyor"),
          value: "quantitySurveyor",
        },
      ],
    },
    {
      id: 6,
      label: t("jobTypes.Gardener"),
      value: "gardener",
      detail:
        "A gardener is a skilled horticulturist responsible for cultivating and maintaining plants, flowers, and green spaces. With expertise in soil health, plant care, and pest management, they ensure optimal growth and aesthetic appeal. Gardeners often perform tasks such as planting, pruning, weeding, and watering. They may also design landscapes, select appropriate plants for specific environments, and implement sustainable practices.",
      icon: <GiGardeningShears />,
      children: [
        {
          label: t("jobTypes.Garden Logs Supplied / Delivered"),
          value: "gardenLogs",
        },
        {
          label: t("jobTypes.Lawn Aeration"),
          value: "lawnAeration",
        },
        {
          label: t("jobTypes.Emergency Garden Service"),
          value: "emergencyGardenService",
        },
        {
          label: t("jobTypes.Japanese Knotweed"),
          value: "japaneseKnotweed",
        },
        {
          label: t("jobTypes.Lawn Care"),
          value: "lawnCare",
        },
        {
          label: t("jobTypes.Plastic Canopies"),
          value: "plasticCanopies",
        },
        {
          label: t("jobTypes.Pond Maintenance / Cleaning"),
          value: "pondMaintenanceCleaning",
        },
        {
          label: t("jobTypes.Polytunnels"),
          value: "polytunnels",
        },
        {
          label: t("jobTypes.Pruning"),
          value: "pruning",
        },
        {
          label: t("jobTypes.Garden Maintenance"),
          value: "gardenMaintenance2",
        },
        {
          label: t("jobTypes.Lawn Treatment"),
          value: "lawnTreatment",
        },
      ],
    },
    {
      id: 7,
      label: t("jobTypes.Surface Repair"),
      value: "surfaceRepair",
      detail:
        "Surface repair involves restoring damaged or deteriorated surfaces to their original or improved condition. The process typically includes cleaning the damaged area, removing any loose or compromised material, and applying a suitable repair material such as epoxy, filler, or patching compound. Proper surface preparation, including sanding and smoothing, ensures optimal adhesion and a seamless finish.",
      icon: <GiHotSurface />,
      children: [
        {
          label: t("jobTypes.Wood & Laminate Repair"),
          value: "woodLaminateRepair",
        },
        {
          label: t("jobTypes.Emergency Surface Repair Service"),
          value: "emergencySurfaceRepairService",
        },
      ],
    },
    {
      id: 8,
      label: t("jobTypes.Removals / Storage"),
      value: "removalsStorage",
      detail:
        "Experience hassle-free removals and storage solutions tailored to your needs! Our expert team ensures a smooth transition, handling packing, transportation, and unpacking with care. We prioritize the safety of your belongings, utilizing secure packing materials and modern storage facilities. ",
      icon: <IoRemoveCircleSharp />,
      children: [
        {
          label: t("jobTypes.Man And A Van"),
          value: "manAndVan",
        },
        {
          label: t("jobTypes.Emergency Removals / Storage Service"),
          value: "emergencyRemovalsStorageService",
        },
        {
          label: t("jobTypes.Haulage"),
          value: "haulage",
        },
        {
          label: t("jobTypes.House Removal"),
          value: "houseRemoval",
        },
        {
          label: t("jobTypes.Refrigerated Transportation"),
          value: "refrigeratedTransportation",
        },
      ],
    },
    {
      id: 9,
      label: t("jobTypes.Appliance Services / Repair"),
      value: "applianceServicesRepair",
      detail:
        "For efficient appliance services and repairs, rely on skilled technicians who specialize in diagnosing and resolving issues with various household devices. These professionals possess expertise in repairing a wide range of appliances, including refrigerators, washing machines, ovens, and more.",
      icon: <MdOutlineHomeRepairService />,
      children: [
        {
          label: t("jobTypes.Appliance Retailer"),
          value: "applianceRetailer",
        },
        {
          label: t("jobTypes.Electric Oven / Cooker Repair"),
          value: "electricOvenCookerRepair",
        },
        {
          label: t("jobTypes.Fridge / Freezer Repair"),
          value: "fridgeFreezerRepair",
        },
        {
          label: t("jobTypes.Fridge / Freezer Repair"),
          value: "fridgeFreezerRepair2",
        },
        {
          label: t("jobTypes.Tumble Dryer Repair"),
          value: "tumbleDryerRepair2",
        },
        {
          label: t("jobTypes.Gas Cooker Repair"),
          value: "gasCookerRepair2",
        },
        {
          label: t("jobTypes.Vacuum Service / Repair"),
          value: "vacuumServiceRepair",
        },
      ],
    },
    {
      id: 10,
      img: images?.electrician,
      icon: images?.electricityIcon,
      label: t("jobTypes.Electrician"),
      value: "electrician",
      description:
        "An electrician is a skilled professional who installs, maintains, and repairs electrical systems in buildings. They work with wiring, fixtures, and appliances to ensure safety and functionality in residential and commercial spaces. Find your qualified electrician roofer on GooseFix.com today ",
      detail:
        "An electrician is a skilled professional who installs, maintains, and repairs electrical systems in buildings. They work with wiring, fixtures, and appliances to ensure safety and functionality in residential and commercial spaces. Find your qualified electrician roofer on GooseFix.com today ",
      children: [
        {
          label: t("jobTypes.Smoke Alarms"),
          value: "smokeAlarms",
        },
        {
          label: t("jobTypes.Electrical Contractors"),
          value: "electricalContractors",
        },
        {
          label: t("jobTypes.Electric Range Cookers"),
          value: "electricRangeCookers",
        },
        {
          label: t("jobTypes.Electric Cookers"),
          value: "electricCookers",
        },
        {
          label: t(
            "jobTypes.Electrical Installation Condition Report - Safety Checks - EICR"
          ),
          value: "eicr",
        },
        {
          label: t("jobTypes.Emergency Electrician Service"),
          value: "emergencyElectricianService",
        },
        {
          label: t("jobTypes.Fault Finding"),
          value: "faultFinding",
        },
        {
          label: t("jobTypes.Garden Lighting"),
          value: "gardenLighting",
        },
        {
          label: t("jobTypes.Electric Gate Wiring"),
          value: "electricGateWiring",
        },
        {
          label: t("jobTypes.House Rewiring"),
          value: "houseRewiring",
        },
        {
          label: t("jobTypes.Electric Boiler Installation"),
          value: "electricBoilerInstallation",
        },
        {
          label: t("jobTypes.LED Lighting"),
          value: "ledLighting",
        },
        {
          label: t("jobTypes.Extractor Fans"),
          value: "extractorFans",
        },
        {
          label: t("jobTypes.Electrical Heater Repair"),
          value: "electricalHeaterRepair",
        },
        {
          label: t("jobTypes.Electric Smart Thermostats"),
          value: "electricSmartThermostats",
        },
        {
          label: t("jobTypes.Fuseboard / Consumer Unit Installation"),
          value: "fuseboardConsumerUnitInstallation",
        },
        {
          label: t("jobTypes.Fuseboard / Consumer Unit Repair"),
          value: "fuseboardConsumerUnitRepair",
        },
        {
          label: t("jobTypes.Electrical Under Floor Heating Repair"),
          value: "electricalUnderFloorHeatingRepair",
        },
        {
          label: t("jobTypes.Electrical Under Floor Heating Installation"),
          value: "electricalUnderFloorHeatingInstallation",
        },
      ],
    },
    {
      id: 11,
      img: images?.carpenter,
      icon: images?.carpenteryIcon,
      label: t("jobTypes.Carpenter"),
      value: "carpenter",
      description:
        "A carpenter is a skilled professional who crafts, installs, and repairs wooden structures. They work with tools to build items like furniture and framing for buildings, playing a key role in construction and renovation projects. Carpenters contribute to the aesthetic and functional aspects of residential and commercial spaces. Find your perfect carpenter on GooseFix.com today",
      detail:
        "A carpenter is a skilled professional who crafts, installs, and repairs wooden structures. They work with tools to build items like furniture and framing for buildings, playing a key role in construction and renovation projects. Carpenters contribute to the aesthetic and functional aspects of residential and commercial spaces. Find your perfect carpenter on GooseFix.com today",
      children: [
        {
          label: t("jobTypes.Architraves"),
          value: "architraves",
        },
        {
          label: t("jobTypes.Boat Building / Repairs"),
          value: "boatBuildingRepairs",
        },
        {
          label: t("jobTypes.Handmade Kitchens Designed / Installed"),
          value: "handmadeKitchens",
        },
        {
          label: t("jobTypes.Bespoke Windows / Doors"),
          value: "bespokeWindowsDoors",
        },
        {
          label: t("jobTypes.Emergency Carpenter Service"),
          value: "emergencyCarpenterService",
        },
        {
          label: t("jobTypes.Built In Furniture"),
          value: "builtInFurniture",
        },
        {
          label: t("jobTypes.Joinery"),
          value: "joinery",
        },
        {
          label: t("jobTypes.Log Cabins"),
          value: "logCabins",
        },
        {
          label: t("jobTypes.Staircases"),
          value: "staircases",
        },
        {
          label: t("jobTypes.Bespoke Staircases"),
          value: "bespokeStaircases",
        },
      ],
    },
    {
      id: 12,
      label: t("jobTypes.Painter / Decorator"),
      value: "painterDecorator",
      detail:
        "A skilled painter/decorator enhances interior and exterior spaces with a keen eye for aesthetics and precision. Proficient in surface preparation, they meticulously apply coatings, ensuring a flawless finish. Their expertise includes selecting and blending colors, advising on suitable textures, and employing various techniques such as faux finishing or stenciling. ",
      icon: <AiFillFormatPainter />,
      children: [
        {
          label: t("jobTypes.Anti Vandal Coatings"),
          value: "antiVandalCoatings",
        },
        {
          label: t("jobTypes.Exterior Painting"),
          value: "exteriorPainting",
        },
        {
          label: t("jobTypes.Paint Sprayer"),
          value: "paintSprayer",
        },
        {
          label: t("jobTypes.Wooden Windows Stripped / Painted"),
          value: "woodenWindowsStrippedPainted",
        },
        {
          label: t("jobTypes.Timber Restoration"),
          value: "timberRestoration",
        },
      ],
    },
    {
      id: 13,
      label: t("jobTypes.Mobility"),
      value: "mobility",
      detail:
        "Mobility refers to the ability to move or be moved freely and easily. In the context of technology and transportation, it often relates to the ease of travel and access to various modes of transportation. Emerging technologies like electric and autonomous vehicles, along with innovations in public transportation and urban planning, contribute to enhanced mobility.",
      icon: <SiIledefrancemobilites />,
      children: [
        {
          label: t("jobTypes.Mobility Aids"),
          value: "mobilityAids",
        },
        {
          label: t("jobTypes.Adjustable Beds"),
          value: "adjustableBeds",
        },
        {
          label: t("jobTypes.Adaptions in the Home"),
          value: "adaptionsInTheHome",
        },
        {
          label: t("jobTypes.Access Ramp Indoor / Outdoor"),
          value: "accessRampIndoorOutdoor",
        },
        {
          label: t("jobTypes.Emergency Mobility Service"),
          value: "emergencyMobilityService",
        },
        {
          label: t("jobTypes.Stairlift Repair"),
          value: "stairliftRepair",
        },
      ],
    },
    {
      id: 14,
      label: t("jobTypes.Driveways / Patios / Paths"),
      value: "drivewaysPatiosPaths",
      detail:
        "Driveways, patios, and paths are crucial elements in exterior design, enhancing both aesthetics and functionality. Driveways provide a welcoming entrance, requiring durable materials like concrete or asphalt for vehicular traffic. Patios serve as outdoor living spaces, often crafted with materials like stone, concrete pavers, or wood decking for a pleasant ambiance.",
      icon: <PiPath />,
      children: [
        {
          label: t("jobTypes.Driveway / Patio Design"),
          value: "drivewayPatioDesign",
        },
        {
          label: t("jobTypes.Drop Kerbs"),
          value: "dropKerbs",
        },
        {
          label: "Soakaways",
          value: "soakaways",
        },
        {
          label: t("jobTypes.Bonded Aggregate Surfacing"),
          value: "bondedAggregateSurfacing",
        },
        {
          label: t("jobTypes.Block Paving"),
          value: "blockPaving",
        },
        {
          label: t("jobTypes.Patios / Paving"),
          value: "patiosPaving",
        },
        {
          label: t("jobTypes.Liquid Waterproof System"),
          value: "liquidWaterproofSystem ",
        },
        {
          label: t("jobTypes.Permeable Paving"),
          value: "permeablePaving",
        },
        {
          label: t("jobTypes.Polished Concrete"),
          value: "polishedConcrete",
        },
        {
          label: t("jobTypes.Gritting"),
          value: "gritting",
        },
        {
          label: t("jobTypes.Driveway Repair"),
          value: "drivewayRepair",
        },
      ],
    },
    {
      id: 15,
      label: t("jobTypes.Landlord Services"),
      value: "landlordServices",
      detail:
        "Landlord services encompass a range of offerings to facilitate smooth property management. These services often include tenant screening, lease agreement preparation, rent collection, property maintenance coordination, and dispute resolution. Landlords may also benefit from financial services like accounting and tax assistance. ",
      icon: <RiFlightLandLine />,
      children: [
        {
          label: t("jobTypes.Portable Appliance Testing - PAT Testing"),
          value: "patTesting",
        },
        {
          label: t("jobTypes.Landlord Inventories - Mid Tenancy Inspection"),
          value: "landlordInventoriesMidTenancyInspection",
        },
        {
          label: t("jobTypes.Landlord Inventories - Tenant Check Out"),
          value: "landlordInventoriesTenantCheckOut",
        },
        {
          label: t("jobTypes.Emergency Landlord Service"),
          value: "emergencyLandlordService",
        },
      ],
    },
    {
      id: 16,
      label: t("jobTypes.Plasterer"),
      value: "plasterer",
      img: images.plastering,
      icon: images?.plasteringIcon,
      description:
        "A plasterer is a skilled professional who specializes in applying plaster to surfaces such as walls and ceilings. Their expertise is crucial in both construction and renovation projects, ensuring the integrity and visual appeal of the finished surfaces. Find your perfect plasterer on GooseFix.com today",
      detail:
        "A plasterer is a skilled professional who specializes in applying plaster to surfaces such as walls and ceilings. Their expertise is crucial in both construction and renovation projects, ensuring the integrity and visual appeal of the finished surfaces. Find your perfect plasterer on GooseFix.com today",
      children: [
        {
          label: t("jobTypes.Tape & Jointing"),
          value: "tapeAndJointing",
        },
        {
          label: t("jobTypes.Spray Rendering"),
          value: "sprayRendering",
        },
        {
          label: "Artexing",
          value: "artexing",
        },
        {
          label: t("jobTypes.Dry Lining"),
          value: "dryLining",
        },
        {
          label: t("jobTypes.Polished Plastering"),
          value: "polishedPlastering",
        },
        {
          label: t("jobTypes.Waterproof Plasterer"),
          value: "waterproofPlasterer",
        },
        {
          label: "K Rend",
          value: "kRend",
        },
        {
          label: t("jobTypes.Lath Plastering"),
          value: "lathPlastering",
        },
        {
          label: t("jobTypes.Liquid Screeding"),
          value: "liquidScreeding",
        },
      ],
    },
    {
      id: 17,
      label: t("jobTypes.Builder"),
      value: "builder",
      detail:
        "Builders are professionals responsible for constructing structures, such as homes, commercial buildings, or infrastructure projects. They interpret blueprints, manage construction teams, and coordinate various aspects of the building process, ensuring compliance with safety regulations and project specifications. Builders oversee material procurement, budgeting, and timelines.",
      icon: <SiElectronbuilder />,
      children: [
        {
          label: t("jobTypes.Diamond Drilling"),
          value: "diamondDrilling",
        },
        {
          label: t("jobTypes.Demolition"),
          value: "demolition",
        },
        {
          label: t("jobTypes.New Builds"),
          value: "newBuilds",
        },
        {
          label: t("jobTypes.General Building"),
          value: "generalBuilding",
        },
        {
          label: t("jobTypes.Concreting"),
          value: "concreting",
        },
        {
          label: t("jobTypes.Insurance Claim Specialist"),
          value: "insuranceClaimSpecialist",
        },
        {
          label: t("jobTypes.Emergency Home Improvement Service"),
          value: "emergencyHomeImprovementService",
        },
        {
          label: t("jobTypes.Emergency Builder Service"),
          value: "emergencyBuilderService",
        },
        {
          label: t("jobTypes.Fire and Flood Renovation"),
          value: "fireAndFloodRenovation",
        },
        {
          label: t("jobTypes.Garage Conversions"),
          value: "garageConversions",
        },
        {
          label: t("jobTypes.Concrete Garages"),
          value: "concreteGarages",
        },
        {
          label: t("jobTypes.Green Oak Framing"),
          value: "greenOakFraming",
        },
        {
          label: t("jobTypes.Tree House"),
          value: "treeHouse",
        },
        {
          label: "Project Management",
          value: "projectManagement",
        },
        {
          label: t("jobTypes.Period Property Restoration"),
          value: "periodPropertyRestoration",
        },
        {
          label: t("jobTypes.Extensions / Conversions"),
          value: "extensionsConversions",
        },
        {
          label: t("jobTypes.Heritage Property Specialist"),
          value: "heritagePropertySpecialist",
        },
        {
          label: t("jobTypes.Property Maintenance / Repair"),
          value: "propertyMaintenanceRepair",
        },
        {
          label: t("jobTypes.Equestrian Stable"),
          value: "equestrianStable",
        },
        {
          label: t("jobTypes.Suspended Ceilings"),
          value: "suspendedCeilings",
        },
      ],
    },
    {
      id: 18,
      label: t("jobTypes.Plumber"),
      value: "plumber",
      img: images?.plumbing,
      icon: images?.plumbingIcon,
      description:
        "A plumber is a skilled professional who installs and maintains plumbing systems in buildings. They work with pipes, fixtures, and fittings, ensuring proper water supply, drainage, and heating. Plumbers address issues like leaks and clogs, contributing to the efficiency and safety of water systems in residential and commercial settings. Find your qualified plumber on GooseFix.com today",
      detail:
        "A plumber is a skilled professional who installs and maintains plumbing systems in buildings. They work with pipes, fixtures, and fittings, ensuring proper water supply, drainage, and heating. Plumbers address issues like leaks and clogs, contributing to the efficiency and safety of water systems in residential and commercial settings. Find your qualified plumber on GooseFix.com today",
      children: [
        {
          label: t("jobTypes.Blocked Baths"),
          value: "blockedBaths",
        },
        {
          label: t("jobTypes.Bathroom & Kitchen Plumbing"),
          value: "bathroomKitchenPlumbing",
        },
        {
          label: t("jobTypes.pipe Fitter"),
          value: "pipeFitter",
        },
        {
          label: t("jobTypes.Plumbing Repairs"),
          value: "plumbingRepairs",
        },
        {
          label: t("jobTypes.Blocked Sinks"),
          value: "blockedSinks",
        },
        {
          label: t("jobTypes.Blocked Toilets"),
          value: "blockedToilets",
        },
        {
          label: t("jobTypes.Emergency Plumber Service"),
          value: "emergencyPlumberService",
        },
        {
          label: t("jobTypes.General Plumbing"),
          value: "generalPlumbing",
        },
        {
          label: t(
            "jobTypes.Water Mains Specialist (Pipe Replacement, Moling and Lead Pipes)"
          ),
          value: "waterMainsSpecialist",
        },
        {
          label: t("jobTypes.Water Pumps Repair"),
          value: "waterPumpsRepair",
        },
        {
          label: t("jobTypes.Water Supply Pipe Repairs"),
          value: "waterSupplyPipeRepairs",
        },
        {
          label: t("jobTypes.Plumbing Merchants"),
          value: "plumbingMerchants",
        },
        {
          label: t("jobTypes.Radiator Repair"),
          value: "radiatorRepair",
        },
        {
          label: t("jobTypes.Shower Repair"),
          value: "showerRepair",
        },
        {
          label: t("jobTypes.Radiator Thermostat Valve Repair"),
          value: "radiatorThermostatValveRepair",
        },
        {
          label: t("jobTypes.Radiator Thermostat Valve Installation"),
          value: "radiatorThermostatValveInstallation",
        },
        {
          label: t("jobTypes.Unvented Hot Water Cylinder Installation"),
          value: "unventedHotWaterCylinderInstallation",
        },
        {
          label: t("jobTypes.Unvented Hot Water Cylinder Servicing / Repair"),
          value: "unventedHotWaterCylinderServicingRepair",
        },
        {
          label: t("jobTypes.Underfloor Heating Repair"),
          value: "underfloorHeatingRepair",
        },
        {
          label: t("jobTypes.Underfloor Heating Installation"),
          value: "underfloorHeatingInstallation",
        },
      ],
    },
    {
      id: 19,
      label: t("jobTypes.Telecommunications"),
      value: "telecommunications",
      detail:
        "Telecommunications refers to the transmission of information over a distance using various technologies. It encompasses a broad range of communication services, including telephone networks, internet connectivity, radio and television broadcasting, and mobile communication. ",
      icon: <SiTelegraph />,
      children: [
        {
          label: t("jobTypes.Broadband Fault Finding"),
          value: "broadbandFaultFinding",
        },
        {
          label: t("jobTypes.Telephone Points"),
          value: "telephonePoints",
        },
        {
          label: t("jobTypes.Broadband Repair"),
          value: "broadbandRepair",
        },
        {
          label: t("jobTypes.Telephone System Equipment"),
          value: "telephoneSystemEquipment2",
        },
        {
          label: t("jobTypes.Telephone Engineer"),
          value: "telephoneEngineer",
        },
        {
          label: t("jobTypes.Fixed Lines"),
          value: "fixedLines",
        },
        {
          label: t("jobTypes.VolP Telephony"),
          value: "volpTelephony",
        },
        {
          label: t("jobTypes.Emergency Telecommunication Service"),
          value: "emergencyTelecommunicationService",
        },
      ],
    },
    {
      id: 20,
      label: t("jobTypes.Miscellaneous Services"),
      value: "miscellaneousServices",
      detail:
        "Miscellaneous services encompass a broad range of offerings that don't fit into specific categories. These may include event planning, personal assistance, pet services, and more. Whether it's organizing events, running errands, or providing unique solutions, miscellaneous services cater to diverse needs.",
      icon: <MdOutlineMiscellaneousServices />,
      children: [
        {
          label: t("jobTypes.Bike / Bicycle Repairs"),
          value: "bikeBicycleRepairs",
        },
        {
          label: t("jobTypes.Block Management"),
          value: "blockManagement",
        },
        {
          label: t("jobTypes.Trailer Towing Training"),
          value: "trailerTowingTraining",
        },
        {
          label: t("jobTypes.Dust / Fume Extraction"),
          value: "dustFumeExtraction",
        },
        {
          label: t("jobTypes.Miscellaneous Services"),
          value: "emergencyMiscellaneousService",
        },
        {
          label: t("jobTypes.Bouncy Castle Hire"),
          value: "bouncyCastleHire",
        },
        {
          label: t("jobTypes.Flat Management"),
          value: "flatManagement",
        },
        {
          label: t("jobTypes.Aquariums"),
          value: "aquariums",
        },
        {
          label: t("jobTypes.Sign Writers"),
          value: "signWriters",
        },
      ],
    },
    {
      id: 21,
      label: t("jobTypes.Renewable Energy"),
      value: "renewableEnergy",
      detail:
        "Renewable energy is derived from naturally replenishing sources, such as sunlight, wind, and water. Solar power harnesses the sun's energy through photovoltaic cells, while wind turbines convert wind into electricity.",
      icon: <SlEnergy />,
      children: [
        {
          label: t("jobTypes.Biomass Boiler Installation"),
          value: "biomassBoilerInstallation",
        },
        {
          label: t("jobTypes.Biomass Energy"),
          value: "biomassEnergy",
        },
        {
          label: t("jobTypes.Emergency Renewable Energy Service"),
          value: "emergencyRenewableEnergyService",
        },
        {
          label: t("jobTypes.Wind Turbines - Feed In Tariff"),
          value: "windTurbinesFeedInTariff",
        },
        {
          label: t("jobTypes.Solar Photovoltaic / Electric - Feed In Tariff"),
          value: "solarPhotovoltaicElectricFeedInTariff",
        },
      ],
    },
    {
      id: 22,
      label: t("jobTypes.Bathrooms"),
      value: "bathrooms",
      detail:
        "Bathrooms are vital spaces designed for personal hygiene and relaxation. Typically, they feature a toilet, sink, and shower or bathtub. Modern bathrooms often prioritize aesthetics, incorporating stylish tiles, fixtures, and lighting. Ventilation is crucial to prevent moisture buildup.",
      icon: <FaBath />,
      children: [
        {
          label: t("jobTypes.Disabled Bathrooms / Showers"),
          value: "disabledBathroomsShowers",
        },
        {
          label: t("jobTypes.Bathroom Fitter"),
          value: "bathroomFitter",
        },
        {
          label: t("jobTypes.Bathroom Showroom"),
          value: "bathroomShowroom",
        },
        {
          label: t("jobTypes.Wet Rooms"),
          value: "wetRooms",
        },
      ],
    },
    {
      id: 23,
      label: t("jobTypes.Curtains / Blinds / Shutters"),
      value: "curtainsBlindsShutters",
      detail:
        "Curtains, blinds, and shutters are window treatments that enhance privacy, control light, and contribute to interior aesthetics. Curtains, made from various fabrics, offer a versatile and soft look, allowing for customizable styles and textures. Blinds, typically crafted from materials like wood, metal, or plastic, provide adjustable light filtration and a modern appearance.",
      icon: <GiTheaterCurtains />,
      children: [
        {
          label: t("jobTypes.Blind Fitter"),
          value: "blindFitter",
        },
        {
          label: t("jobTypes.Roof Blinds"),
          value: "roofBlinds",
        },
        {
          label: t("jobTypes.Integral Blinds"),
          value: "integralBlinds",
        },
        {
          label: t("jobTypes.Made to Measure Blinds"),
          value: "madeToMeasureBlinds",
        },
        {
          label: t("jobTypes.Made to Measure Shutters"),
          value: "madeToMeasureShutters",
        },
        {
          label: t("jobTypes.Made to Measure Curtain"),
          value: "madeToMeasureCurtain",
        },
        {
          label: t("jobTypes.Roller Shutters"),
          value: "rollerShutters",
        },
        {
          label: t("jobTypes.Sail Shades"),
          value: "sailShades",
        },
      ],
    },
    {
      id: 24,
      label: t("jobTypes.Cleaning Services"),
      value: "cleaningServices",
      detail:
        "Cleaning services encompass a range of professional solutions dedicated to maintaining cleanliness in various environments. Typically offered for residential, commercial, or industrial spaces, these services include dusting, vacuuming, mopping, and sanitizing surfaces.",
      icon: <MdCleaningServices />,
      children: [
        {
          label: t("jobTypes.BBQ Cleaning"),
          value: "bbqCleaning",
        },
        {
          label: t("jobTypes.Emergency / Fire / Flood Cleaning"),
          value: "emergencyFireFloodCleaning",
        },
        {
          label: t("jobTypes.Tile and Grout Cleaning"),
          value: "tileAndGroutCleaning",
        },
        {
          label: t("jobTypes.Jet / Pressure Washing"),
          value: "jetPressureWashing",
        },
        {
          label: t("jobTypes.Kitchen Extraction Cleaning"),
          value: "kitchenExtractionCleaning",
        },
        {
          label: t("jobTypes.Leather Cleaning"),
          value: "leatherCleaning",
        },
        {
          label: t("jobTypes.Holiday Let Cleaning Services"),
          value: "holidayLetCleaningServices",
        },
        {
          label: t("jobTypes.Moss Removal Service"),
          value: "mossRemovalService",
        },
        {
          label: t("jobTypes.Render Cleaning"),
          value: "renderCleaning",
        },
        {
          label: t("jobTypes.End of Tenancy / Move Cleaning"),
          value: "endOfTenancyMoveCleaning",
        },
        {
          label: t("jobTypes.Office Cleaning"),
          value: "officeCleaning",
        },
        {
          label: t("jobTypes.UPVC Cleaning"),
          value: "upvcCleaning",
        },
        {
          label: t("jobTypes.Organic Cleaning"),
          value: "organicCleaning",
        },
        {
          label: t("jobTypes.Soda / Sand / Shot Blasting"),
          value: "sodaSandShotBlasting",
        },
        {
          label: t("jobTypes.Emergency Cleaning Service"),
          value: "emergencyCleaningService",
        },
        {
          label: t("jobTypes.Commercial Cleaning"),
          value: "commercialCleaning",
        },
        {
          label: t("jobTypes.Ironing"),
          value: "ironing",
        },
      ],
    },
    {
      id: 25,
      label: t("jobTypes.IT and Consultancy"),
      value: "itAndConsultancy",
      detail:
        "IT consultancy involves providing expert advice on information technology to help businesses optimize their systems and achieve goals. Consultants analyze existing infrastructure, recommend improvements, and implement solutions. Services may cover cybersecurity, software development, cloud computing, and data management.",
      icon: <GiLaptop />,
      children: [
        {
          label: t("jobTypes.B2B IT Support"),
          value: "b2bItSupport",
        },
        {
          label: t("jobTypes.Graphic Designers"),
          value: "graphicDesigners",
        },
        {
          label: t("jobTypes.IT Project Management"),
          value: "itProjectManagement",
        },
        {
          label: t("jobTypes.Computer Maintenance"),
          value: "computerMaintenance",
        },
        {
          label: t("jobTypes.PC Repairs"),
          value: "pcRepairs",
        },
        {
          label: t("jobTypes.Software Testing"),
          value: "softwareTesting",
        },
        {
          label: t("jobTypes.Printer Repairs"),
          value: "printerRepairs",
        },
        {
          label: t("jobTypes.Mobile Phone Repairs"),
          value: "mobilePhoneRepairs",
        },
        {
          label: t("jobTypes.Cyber Security Training"),
          value: "cyberSecurityTraining",
        },
        {
          label: t("jobTypes.Network Installation"),
          value: "networkInstallation",
        },
        {
          label: t("jobTypes.Network Administration"),
          value: "networkAdministration",
        },
        {
          label: t("jobTypes.Home Networks"),
          value: "homeNetworks",
        },
        {
          label: t("jobTypes.Network Support"),
          value: "networkSupport",
        },
        {
          label: t("jobTypes.IT Planning and Construction"),
          value: "itPlanningAndConstruction",
        },
        {
          label: t("jobTypes.Data Recovery / Back up"),
          value: "dataRecoveryBackup",
        },
      ],
    },
    {
      id: 26,
      label: t("jobTypes.Bricklayer"),
      value: "bricklayer",
      detail:
        "A bricklayer, also known as a mason, is a skilled artisan specializing in the construction and repair of structures using bricks, concrete blocks, or other masonry materials. Their expertise lies in accurately laying bricks, ensuring a strong and aesthetically pleasing result.",
      icon: <BsBricks />,
      children: [
        {
          label: t("jobTypes.Brickwork"),
          value: "brickwork",
        },
        {
          label: t("jobTypes.Emergency Bricklayer Service"),
          value: "emergencyBricklayerService",
        },
      ],
    },
    {
      id: 27,
      label: t("jobTypes.Metalwork / Blacksmith"),
      value: "metalworkBlacksmith",
      detail:
        "Metalwork, a craft steeped in ancient traditions, encompasses the art of shaping and manipulating metals into functional and aesthetic forms. Blacksmithing, a subset of metalwork, involves heating iron or steel until malleable and then skillfully hammering, bending, or twisting it into desired shapes.",
      icon: <GiBlacksmith />,
      children: [
        {
          label: "Balustrades",
          value: "balustrades",
        },
        {
          label: t("jobTypes.Welding"),
          value: "welding",
        },
        {
          label: t("jobTypes.Gates"),
          value: "gates",
        },
        {
          label: t("jobTypes.Handrails"),
          value: "handrails",
        },
        {
          label: t("jobTypes.Emergency Metalwork / Blacksmith Service"),
          value: "emergencyMetalworkBlacksmithService",
        },
        {
          label: t("jobTypes.Metal Works"),
          value: "metalWorks",
        },
      ],
    },
    {
      id: 28,
      label: t("jobTypes.Drain / Sewer Clearance"),
      value: "drainSewerClearance",
      detail:
        "Drain and sewer clearance involves removing blockages and debris from plumbing systems to ensure proper wastewater flow. Specialized professionals, often equipped with high-pressure water jets or augers, identify and clear obstructions such as grease, roots, or foreign objects.",
      icon: <MdDryCleaning />,
      children: [
        {
          label: t("jobTypes.Blocked Drains"),
          value: "blockedDrains",
        },
        {
          label: t("jobTypes.Septic Tank Installation"),
          value: "septicTankInstallation",
        },
        {
          label: t("jobTypes.Waste Water Treatment Plant Installations"),
          value: "wasteWaterTreatmentPlantInstallations",
        },
        {
          label: t("jobTypes.Emergency Drain / Sewer Clearance Service"),
          value: "emergencyDrainSewerClearanceService",
        },
        {
          label: t("jobTypes.Sewage Pump Maintenance"),
          value: "sewagePumpMaintenance",
        },
        {
          label: t("jobTypes.Drain Relining"),
          value: "drainRelining",
        },
      ],
    },
    {
      id: 29,
      label: t("jobTypes.Rubbish / Waste / Clearance"),
      value: "rubbishWasteClearance",
      detail:
        "Rubbish and waste management involves the systematic collection, disposal, and recycling of discarded materials to mitigate environmental impact. Clearance procedures encompass the organized removal of unwanted items from a given area, ensuring proper sorting and disposal.",
      icon: <GiNuclearWaste />,
      children: [
        {
          label: t("jobTypes.Data Shredding"),
          value: "dataShredding",
        },
        {
          label: t("jobTypes.Office Clearance"),
          value: "officeClearance",
        },
        {
          label: t("jobTypes.Scrap Cars"),
          value: "scrapCars",
        },
        {
          label: "Recycling",
          value: "recycling",
        },
        {
          label: t("jobTypes.House Clearance"),
          value: "houseClearance",
        },
        {
          label: t("jobTypes.Waste Clearance"),
          value: "wasteClearance",
        },
        {
          label: t("jobTypes.Emergency Rubbish / Waste / Clearance Service"),
          value: "emergencyRubbishWasteClearanceService",
        },
      ],
    },
    {
      id: 30,
      label: t("jobTypes.Carpet and Upholstery Cleaning"),
      value: "carpetAndUpholsteryCleaning",
      detail:
        "Carpet and upholstery cleaning involves removing dirt, stains, and allergens from carpets, rugs, and furniture fabrics. Professional cleaners use various methods such as steam cleaning, dry cleaning, or hot water extraction to lift and extract deep-seated grime.",
      icon: <GiRedCarpet />,
      children: [
        {
          label: t("jobTypes.Carpet Cleaning"),
          value: "carpetCleaning",
        },
        {
          label: t("jobTypes.Curtain Cleaning"),
          value: "curtainCleaning",
        },
        {
          label: t("jobTypes.Upholstery Cleaning"),
          value: "upholsteryCleaning",
        },
        {
          label: t("jobTypes.Emergency Carpet and Upholstery Cleaning Service"),
          value: "emergencyCarpetAndUpholsteryCleaningService",
        },
      ],
    },
    {
      id: 31,
      label: t("jobTypes.Fencing / Gates"),
      value: "fencingGates",
      detail:
        "Fencing and gates serve both practical and aesthetic purposes, providing security, privacy, and defining property boundaries. Typically constructed from materials like wood, metal, or vinyl, fences vary in height and style, ranging from classic picket designs to modern minimalist options.",
      icon: <GiOpenGate />,
      children: [
        {
          label: t("jobTypes.Fencing Contractor"),
          value: "fencingContractor",
        },
        {
          label: t("jobTypes.Emergency Fencing / Gate Service"),
          value: "emergencyFencingGateService",
        },
        {
          label: t("jobTypes.Panel Fencing"),
          value: "panelFencing",
        },
        {
          label: t("jobTypes.Picket Fencing"),
          value: "picketFencing",
        },
        {
          label: t("jobTypes.Garden Fencing"),
          value: "gardenFencing",
        },
      ],
    },
    {
      id: 32,
      label: t("jobTypes.Central Heating"),
      value: "centralHeating",
      detail:
        "Central heating is a system that efficiently warms an entire building by distributing heat from a central source. Typically, a boiler or furnace generates hot water or steam, which is then circulated through pipes or ducts to radiators or vents in various rooms. This method ensures consistent warmth throughout the space.",
      icon: <GiHeatHaze />,
      children: [
        {
          label: t("jobTypes.Gas Safety Checks"),
          value: "gasSafetyChecks",
        },
        {
          label: t("jobTypes.Solid Fuel"),
          value: "solidFuel",
        },
        {
          label: t("jobTypes.Oil Boiler Installation"),
          value: "oilBoilerInsulation",
        },
        {
          label: t("jobTypes.Oil Tank Installation"),
          value: "oilTankInsulation",
        },
        {
          label: t("jobTypes.LPG Boiler Installation"),
          value: "lpgBoilerInsulation",
        },
        {
          label: t("jobTypes.Air Source Heat Pump Servicing / Repair - ASHP"),
          value: "airSourceHeatPumpServicingRepair",
        },
        {
          label: t(
            "jobTypes.Ground Source Heat Pump Servicing / Repair - GSHP"
          ),
          value: "groundSourceHeatPumpServicingRepair",
        },
        {
          label: t("jobTypes.LPG Central Heating Installation"),
          value: "lpgCentralHeatingInstallation",
        },
        {
          label: t("jobTypes.Oil Central Heating Installation"),
          value: "oilCentralHeatingInstallation",
        },
        {
          label: t("jobTypes.Oil Central Heating Servicing / Repair"),
          value: "oilCentralHeatingServicingRepair",
        },
        {
          label: t("jobTypes.Smart Thermostats"),
          value: "smartThermostats",
        },
      ],
    },
    {
      id: 33,
      img: images?.kitchen,
      icon: images?.kitchenIcon,
      label: t("jobTypes.Kitchens"),
      value: "kitchens",
      description:
        "A kitchen fitter is a specialist in customizing and installing kitchen components, including cabinets,counertops, and appliances. They play a vital role in optimizing functionality and aesthetics, ensuringpropr alignment and fit. Whether for new installations or renovations, kitchen fitters contribute tocreaing practical and visually appealing kitchen spaces in homes and businesses. Find your perfect kitcen helper on GooseFix.com today ",
      detail:
        "Kitchens are multifunctional spaces designed for culinary pursuits and social gatherings. Typically equipped with appliances like stoves, refrigerators, and microwaves, kitchens facilitate food preparation and storage. Cabinetry provides storage for utensils, cookware, and pantry items. ",
      children: [
        {
          label: t("jobTypes.Replacement Doors and Drawers"),
          value: "replacementDoorsAndDrawers",
        },
        {
          label: t("jobTypes.Kitchen Designers"),
          value: "kitchenDesigners",
        },
        {
          label: t("jobTypes.Kitchen Supplier"),
          value: "kitchenSupplier",
        },
        {
          label: t("jobTypes.Kitchen Showroom"),
          value: "kitchenShowroom",
        },
        {
          label: t("jobTypes.Bespoke Kitchens - Supply & Installation"),
          value: "bespokeKitchensSupplyInstallation",
        },
        {
          label: t("jobTypes.Accessible Kitchens"),
          value: "accessibleKitchens",
        },
        {
          label: t("jobTypes.Kitchen Doors - Repair"),
          value: "kitchenDoorsRepair",
        },
        {
          label: t("jobTypes.Kitchen Refurbishments"),
          value: "kitchenRefurbishments",
        },
        {
          label: t("jobTypes.Emergency Kitchen Service"),
          value: "emergencyKitchenService",
        },
        {
          label: t("jobTypes.Replace kitchen cabinets"),
          value: "replaceKitchenCabinets",
        },
        {
          label: t("jobTypes.Kitchen Doors - Installation"),
          value: "kitchenDoorsInstallation",
        },
        {
          label: t("jobTypes.Painting Kitchen Cabinets"),
          value: "paintingKitchenCabinets",
        },
        {
          label: t("jobTypes.Kitchen Appliance Supplier"),
          value: "kitchenApplianceSupplier",
        },
        {
          label: t("jobTypes.Kitchen Fitters - Installation"),
          value: "kitchenFittersInstallation",
        },
        {
          label: t("jobTypes.Kitchen Doors - Supply & Installation"),
          value: "kitchenDoorsSupplyInstallation",
        },
        {
          label: t("jobTypes.Kitchen Fitters - Supply & Installation"),
          value: "kitchenFittersSupplyInstallation",
        },
        {
          label: t("jobTypes.Worktops"),
          value: "worktops",
        },
        {
          label: t("jobTypes.Worktops - Quartz"),
          value: "worktopsQuartz",
        },
        {
          label: t("jobTypes.Worktops - Stainless Steel"),
          value: "worktopsStainlessSteel",
        },
        {
          label: t("jobTypes.Worktops - Solid Wood"),
          value: "worktopsSolidWood",
        },
      ],
    },
    {
      id: 34,
      label: t("jobTypes.Tiler - Tiling"),
      value: "tilerTiling",
      detail:
        "A tiler meticulously transforms spaces by skillfully arranging tiles, ensuring precision and aesthetic appeal. Armed with expertise, they assess surfaces, select appropriate tiles, and meticulously apply adhesives for a flawless finish.",
      icon: <GiDominoTiles />,
      children: [
        {
          label: t("jobTypes.Ceramic Tiling"),
          value: "ceramicTiling",
        },
        {
          label: t("jobTypes.Hand Made Tiles"),
          value: "handMadeTiles",
        },
        {
          label: t("jobTypes.Natural Stone Tiles"),
          value: "naturalStoneTiles",
        },
        {
          label: t("jobTypes.Quartz Tiling"),
          value: "quartzTiling",
        },
        {
          label: t("jobTypes.Victorian Tiles"),
          value: "victorianTiles",
        },
        {
          label: t("jobTypes.Wall Tiling"),
          value: "wallTiling",
        },
      ],
    },
    {
      id: 35,
      label: t("jobTypes.Fascia / Soffits / Guttering"),
      value: "fasciaSoffitsGuttering",
      detail:
        "Fascia, soffits, and guttering collectively form an essential trio in roofing systems. The fascia is a horizontal band beneath the roof edge, supporting guttering and providing a finishing touch. Soffits are installed beneath the fascia, closing the gap between the roofline and house exterior. ",
      icon: <FaScreenpal />,
      children: [
        {
          label: t("jobTypes.Concrete / Finlock Guttering"),
          value: "concreteFinlockGuttering",
        },
        {
          label: t("jobTypes.Fascia / Soffits Repair & Replacement"),
          value: "fasciaSoffitsRepairReplacement",
        },
        {
          label: t("jobTypes.UPVC Guttering"),
          value: "upvcGuttering",
        },
        {
          label: t("jobTypes.Aluminium Guttering"),
          value: "aluminiumGuttering",
        },
      ],
    },
    {
      id: 36,
      label: t("jobTypes.Transport Services"),
      value: "transportServices",
      detail:
        "Transport services encompass the movement of people and goods, vital for global connectivity. They include diverse modes such as road, rail, air, sea, and public transit. Road transport, facilitated by vehicles ranging from cars to trucks, dominates short-distance travel. Rail services offer efficient, large-scale cargo and passenger transport.",
      icon: <MdEmojiTransportation />,
      children: [
        {
          label: t("jobTypes.Courier Services"),
          value: "courierServices",
        },
        {
          label: "Chauffeur",
          value: "chauffeur",
        },
        {
          label: t("jobTypes.Minibus Taxi"),
          value: "minibusTaxi",
        },
        {
          label: t("jobTypes.Taxi Services"),
          value: "taxiServices",
        },
      ],
    },
    {
      id: 37,
      label: t("jobTypes.Windows / Doors / Conservatories"),
      value: "windowsDoorsConservatories",
      detail:
        "Windows, doors, and conservatories are integral elements in architectural design, enhancing both aesthetics and functionality. Windows provide natural light, ventilation, and views while contributing to energy efficiency. Doors serve as entry points, reflecting style and security. ",
      icon: <BsDoorOpenFill />,
      children: [
        {
          label: t("jobTypes.Windows and Doors"),
          value: "windowsAndDoors",
        },
        {
          label: t("jobTypes.Bifold Doors Repair"),
          value: "bifoldDoorsRepair",
        },
        {
          label: t("jobTypes.Exterior Doors Repair"),
          value: "exteriorDoorsRepair",
        },
        {
          label: t("jobTypes.Porches Repair"),
          value: "porchesRepair",
        },
        {
          label: t("jobTypes.Patio Doors Repair"),
          value: "patioDoorsRepair",
        },
        {
          label: t("jobTypes.Misted Double Glazing"),
          value: "mistedDoubleGlazing",
        },
        {
          label: t("jobTypes.Dog Flaps"),
          value: "dogFlaps",
        },
        {
          label: t("jobTypes.Timber / Wooden Doors Repair"),
          value: "timberWoodenDoorsRepair",
        },
        {
          label: t("jobTypes.Cat Flaps"),
          value: "catFlaps",
        },
        {
          label: t("jobTypes.Window Film"),
          value: "windowFilm",
        },
        {
          label: t("jobTypes.Window Fitters"),
          value: "windowFitters",
        },
        {
          label: t("jobTypes.Wooden Sash Windows Repair"),
          value: "woodenSashWindowsRepair",
        },
        {
          label: t("jobTypes.Secondary Glazing"),
          value: "secondaryGlazing",
        },
        {
          label: t("jobTypes.Orangeries"),
          value: "orangeries",
        },
        {
          label: t("jobTypes.Stained Glass"),
          value: "stainedGlass",
        },
        {
          label: t("jobTypes.Interior Window Shutter Fitters"),
          value: "interiorWindowShutterFitters",
        },
        {
          label: t("jobTypes.Aluminium Windows Replacement / Installation"),
          value: "aluminiumWindowsReplacementInstallation",
        },
        {
          label: t("jobTypes.Loft Windows Replacement / Installation"),
          value: "loftWindowsReplacementInstallation",
        },
        {
          label: t("jobTypes.UPVC Repairs"),
          value: "upvcRepairs",
        },
        {
          label: t("jobTypes.UPVC Spraying"),
          value: "upvcSpraying",
        },
        {
          label: t("jobTypes.UPVC Doors Repair"),
          value: "upvcDoorsRepair",
        },
        {
          label: t("jobTypes.UPVC Windows Repair"),
          value: "upvcWindowsRepair",
        },
        {
          label: t("jobTypes.Exterior Doors Replacement / Installation"),
          value: "exteriorDoorsReplacementInstallation",
        },
        {
          label: t("jobTypes.UPVC Doors Replacement / Installation"),
          value: "upvcDoorsReplacementInstallation",
        },
        {
          label: t("jobTypes.UPVC Windows Replacement / Installation"),
          value: "upvcWindowsReplacementInstallation",
        },
        {
          label: t("jobTypes.Window Tinting"),
          value: "windowTinting",
        },
        {
          label: t("jobTypes.Window Shutters"),
          value: "windowShutters",
        },
        {
          label: t("jobTypes.Window Shutters Fitters"),
          value: "windowShuttersFitters",
        },
        {
          label: t("jobTypes.Window Shutters Suppliers"),
          value: "windowShuttersSuppliers",
        },
        {
          label: t("jobTypes.Frosted Windows"),
          value: "frostedWindows",
        },
        {
          label: t("jobTypes.Loft Windows Repair"),
          value: "loftWindowsRepair",
        },
        {
          label: t("jobTypes.Roof Windows Repair"),
          value: "roofWindowsRepair",
        },
        {
          label: t("jobTypes.Bay Windows Repair"),
          value: "bayWindowsRepair",
        },
        {
          label: t("jobTypes.Timber / Wooden Windows Repair"),
          value: "timberWoodenWindowsRepair",
        },
        {
          label: t("jobTypes.Double Glazing Repair"),
          value: "doubleGlazingRepair",
        },
        {
          label: t("jobTypes.Triple Glazing Repair"),
          value: "tripleGlazingRepair",
        },
        {
          label: t("jobTypes.Triple Glazing Replacement / Installation"),
          value: "tripleGlazingReplacementInstallation",
        },
        {
          label: t("jobTypes.Double Glazing Replacement / Installation"),
          value: "doubleGlazingReplacementInstallation",
        },
      ],
    },
    {
      id: 38,
      label: t("jobTypes.Roofer"),
      value: "roofer",
      img: images?.roofs,
      icon: images?.roofsIcon,
      description:
        "A roofer is a professional skilled in installing, repairing, and maintaining roofs. They work with various  materials to create durable and weather-resistant structures. Roofers play a crucial role in protecting  buildings from the elements by ensuring the integrity of the roof and addressing issues like leaks or  damage. Find your perfect roofer on GooseFix.com today",
      detail:
        "A roofer is a professional skilled in installing, repairing, and maintaining roofs. They work with various  materials to create durable and weather-resistant structures. Roofers play a crucial role in protecting  buildings from the elements by ensuring the integrity of the roof and addressing issues like leaks or  damage. Find your perfect roofer on GooseFix.com today",
      children: [
        {
          label: t("jobTypes.Ridged Tiles Installation"),
          value: "ridgeTilesInstallation",
        },
        {
          label: t("jobTypes.Chimney Cowlings Installation"),
          value: "chemistryCowlingInstallation",
        },
        {
          label: t("jobTypes.Slate Roofer Repair"),
          value: "slateRooferRepair",
        },
        {
          label: t("jobTypes.Tiled Conservatory Roof Repair"),
          value: "tiledConservatoryRoofRepair",
        },
        {
          label: t("jobTypes.Dry Ridge Repair"),
          value: "dryRidgeRepair",
        },
        {
          label: t("jobTypes.Dormer / Roof Windows"),
          value: "dormerRoofWindows",
        },
        {
          label: t("jobTypes.Dry Ridge Installation"),
          value: "dryRidgeInstallation",
        },
        {
          label: t("jobTypes.Emergency Roofing Service"),
          value: "emergencyRoofingService",
        },
        {
          label: t("jobTypes.Flat Roofing Repair"),
          value: "flatRoofingRepair",
        },
        {
          label: t("jobTypes.Flat Roofing Installation"),
          value: "flatRoofingInstallation",
        },
        {
          label: t("jobTypes.Felt Roofing Installation"),
          value: "feltRoofingInstallation",
        },
        {
          label: t("jobTypes.Sealoflex Roofing Repair"),
          value: "sealoflexRoofingRepair",
        },
        {
          label: t("jobTypes.Sealoflex Roofing Installation"),
          value: "sealoflexRoofingInstallation",
        },
        {
          label: t("jobTypes.Fibre Glass Roofing Repair"),
          value: "fibreGlassRoofingRepair",
        },
        {
          label: t("jobTypes.Felt Roofing Repair"),
          value: "feltRoofingRepair",
        },
        {
          label: t("jobTypes.Fibre Glass Roofing Installation"),
          value: "fibreGlassRoofingInstallation",
        },
        {
          label: t("jobTypes.Green Roofing Repair"),
          value: "greenRoofingRepair",
        },
        {
          label: t("jobTypes.Thatched Roofer Installation"),
          value: "thatchedRooferInstallation",
        },
        {
          label: t("jobTypes.Slate / Tiled Roofing Installation"),
          value: "stateTiledRoofingInstallation",
        },
        {
          label: t("jobTypes.Liquid Plastic Roofing Repair"),
          value: "liquidPlasticRoofingRepair",
        },
        {
          label: t("jobTypes.Liquid Plastic Roofing Installation"),
          value: "liquidPlasticRoofingInstallation",
        },
        {
          label: t("jobTypes.Rubber Roofing Installation"),
          value: "rubberRoofingInstallation",
        },
        {
          label: t("jobTypes.Leadworks Installation"),
          value: "leadworksInstallation",
        },
        {
          label: t("jobTypes.Roof Lights Installation"),
          value: "roofLightsInstallation",
        },
        {
          label: t("jobTypes.Moss Removal"),
          value: "mossRemoval",
        },
        {
          label: t("jobTypes.Pitched Roofing Repair"),
          value: "pitchedRoofingRepair",
        },
        {
          label: t("jobTypes.Dry Verge Repair"),
          value: "dryVergeRepair",
        },
        {
          label: t("jobTypes.Dry Verge Installation"),
          value: "dryVergeInstallation",
        },
      ],
    },
    {
      id: 39,
      label: t("jobTypes.Bedrooms"),
      value: "bedrooms",
      detail:
        "Bedrooms serve as personal sanctuaries, designed for rest and relaxation. These intimate spaces typically feature a bed as the focal point, adorned with plush bedding and pillows for comfort. Functional furniture such as dressers and nightstands provide storage and convenience. ",
      icon: <FaBed />,
      children: [
        {
          label: t("jobTypes.Wardrobe Sliding Doors"),
          value: "wardrobeSlidingDoors",
        },
        {
          label: t("jobTypes.Bedroom Planner / Designer"),
          value: "bedroomPlannerDesigner",
        },
        {
          label: t("jobTypes.Home Offices"),
          value: "homeOffices",
        },
        {
          label: t("jobTypes.Bedroom Showroom"),
          value: "bedroomShowroom",
        },
      ],
    },
    {
      id: 40,
      label: t("jobTypes.Locksmith"),
      value: "locksmith",
      detail:
        "A locksmith is a skilled professional specializing in securing and gaining access to various types of locks and security systems. Equipped with expertise in lock installation, repair, and maintenance, locksmiths play a crucial role in ensuring the safety of homes, businesses, and vehicles.",
      icon: <TbLockSearch />,
      children: [
        {
          label: t("jobTypes.Doors Opened / Unlocked"),
          value: "doorsUnlocked",
        },
        {
          label: t("jobTypes.Key Cutting"),
          value: "keyCutting",
        },
        {
          label: t("jobTypes.Car Key Replacement"),
          value: "carKeyReplacement",
        },
        {
          label: t("jobTypes.Window Lock Fitting"),
          value: "windowLockFitting",
        },
        {
          label: t("jobTypes.Window Lock Repair"),
          value: "windowLockRepair",
        },
        {
          label: t("jobTypes.Digital Smart Door Lock Installation"),
          value: "digitalSmartDoorLockInstallation",
        },
        {
          label: t("jobTypes.Snap Locks"),
          value: "snapLocks",
        },
        {
          label: t("jobTypes.Van Security Locks"),
          value: "vanSecurityLocks",
        },
        {
          label: t("jobTypes.Auto / Vehicle Locksmith"),
          value: "autoVehicleLocksmith",
        },
      ],
    },
    {
      id: 41,
      label: t("jobTypes.Drone Surveying"),
      value: "droneSurveying",
      detail:
        "Drone surveying utilizes unmanned aerial vehicles equipped with cameras and sensors to capture detailed aerial data for mapping and analysis. These drones offer a cost-effective and efficient alternative to traditional surveying methods, covering large areas quickly and accessing challenging terrains. ",
      icon: <GiDeliveryDrone />,
      children: [
        {
          label: t("jobTypes.Drone Aerial Roof & Building Surveying"),
          value: "droneAerialRoofBuildingSurveying",
        },
      ],
    },
    {
      id: 42,
      label: t("jobTypes.Garage Doors"),
      value: "garageDoors",
      detail:
        "Garage doors are essential components of residential and commercial structures, providing secure access to vehicle storage spaces. Typically composed of sturdy materials such as steel, aluminum, or wood, these doors come in various designs to complement architectural aesthetics.",
      icon: <PiGarageLight />,
      children: [
        {
          label: t("jobTypes.Industrial Garage Doors"),
          value: "industrialGarageDoors",
        },
        {
          label: t("jobTypes.Emergency Garage Door Service"),
          value: "emergencyGarageDoorService",
        },
        {
          label: t("jobTypes.Electric Garage Door Repair"),
          value: "electricGarageDoorRepair",
        },
        {
          label: t("jobTypes.Garage Door Servicing / Repair"),
          value: "garageDoorServicingRepair",
        },
        {
          label: t("jobTypes.Garage Door Supplier"),
          value: "garageDoorSupplier",
        },
      ],
    },
    {
      id: 43,
      label: t("jobTypes.Damp Proofer"),
      value: "dampProofer",
      detail:
        "A damp proofer is a professional specializing in preventing and remedying damp issues within buildings. Employing various techniques, such as installing damp-proof courses, membranes, or sealants, they safeguard structures from moisture-related damage like mold, rot, and structural decay. ",
      icon: <MdAmpStories />,
      children: [
        {
          label: t("jobTypes.Damp Survey"),
          value: "dampSurvey",
        },
        {
          label: t("jobTypes.Condensation Control"),
          value: "condensationControl",
        },
        {
          label: t("jobTypes.Mould Control"),
          value: "mouldControl",
        },
        {
          label: t("jobTypes.Dry Rot Treatment"),
          value: "dryRotTreatment",
        },
        {
          label: t("jobTypes.Woodworm Treatment"),
          value: "woodwormTreatment",
        },
        {
          label: t("jobTypes.Timber Treatment"),
          value: "timberTreatment",
        },
        {
          label: t("jobTypes.Penetrating Damp Treatment"),
          value: "penetratingDampTreatment",
        },
      ],
    },
    {
      id: 44,
      label: t("jobTypes.Tree Surgeon"),
      value: "treeSurgeon",
      detail:
        "A tree surgeon, also known as an arborist, is a skilled professional dedicated to the care and maintenance of trees. Specializing in the health and safety of trees, these experts assess, diagnose, and treat various tree issues, including diseases, pests, and structural concerns. ",
      icon: <GiBonsaiTree />,
      children: [
        {
          label: t("jobTypes.Emergency Tree Surgeon Service"),
          value: "emergencyTreeSurgeonService",
        },
        {
          label: t("jobTypes.Tree Felling"),
          value: "treeFelling",
        },
        {
          label: t("jobTypes.Stump Grinding"),
          value: "stumpGrinding",
        },
        {
          label: t("jobTypes.Logs"),
          value: "logs",
        },
        {
          label: t("jobTypes.Timber Merchants"),
          value: "timberMerchants",
        },
        {
          label: t("jobTypes.Crown Thinning"),
          value: "crownThinning",
        },
      ],
    },
    {
      id: 45,
      label: t("jobTypes.Chimney Sweep"),
      value: "chimneySweep",
      detail:
        "A chimney sweep is a skilled professional responsible for cleaning and maintaining chimneys to ensure safe and efficient operation. Using specialized tools, sweeps remove creosote, soot, and debris that accumulate in chimneys, reducing the risk of fires and improving air quality. ",
      icon: <GiChimney />,
      children: [
        {
          label: t("jobTypes.Emergency Chimney Sweep Service"),
          value: "emergencyChimneySweepService",
        },
      ],
    },
    {
      id: 46,
      label: t("jobTypes.Asbestos Services"),
      value: "asbestosServices",
      detail:
        "Asbestos services encompass a range of crucial procedures related to the identification, management, and removal of asbestos-containing materials (ACMs). Professionals in this field conduct thorough asbestos surveys to locate and assess the presence of ACMs in buildings. ",
      icon: <DiRasberryPi />,
      children: [
        {
          label: t("jobTypes.Emergency Asbestos Service"),
          value: "emergencyAsbestosService",
        },
        {
          label: t("jobTypes.Asbestos Removal - License"),
          value: "asbestosRemovalLicense",
        },
        {
          label: t("jobTypes.Asbestos Removal - Non License"),
          value: "asbestosRemovalNonLicense",
        },
        {
          label: t("jobTypes.Asbestos Surveys"),
          value: "asbestosSurveys",
        },
      ],
    },
    {
      id: 47,
      label: t("jobTypes.Household Water Treatment"),
      value: "householdWaterTreatment",
      detail:
        "Household water treatment involves methods to improve water quality for safe consumption at home. Common techniques include boiling, chlorination, filtration, and solar disinfection. Boiling kills harmful microorganisms, while chlorination uses chlorine tablets or liquid to disinfect water. ",
      icon: <FaHouseFloodWater />,
      children: [
        {
          label: t("jobTypes.Emergency Household Water Treatment Service"),
          value: "emergencyHouseholdWaterTreatmentService",
        },
        {
          label: t("jobTypes.Water Filters"),
          value: "waterFilters",
        },
        {
          label: t("jobTypes.Legionella Control"),
          value: "legionellaControl",
        },
        {
          label: t("jobTypes.Legionella Testing"),
          value: "legionellaTesting",
        },
        {
          label: t("jobTypes.Legionella Assessment"),
          value: "legionellaAssessment",
        },
      ],
    },
    {
      id: 48,
      label: t("jobTypes.Hire Services"),
      value: "hireServices",
      detail:
        "Celebrate my first birthday by hiring my versatile language services! I offer comprehensive solutions for content creation, copywriting, and creative writing. Whether you need assistance with blog posts, articles, social media content, or any other writing project, I can tailor my abilities to meet your specific needs. ",
      icon: <LiaHireAHelper />,
      children: [
        {
          label: t("jobTypes.Emergency Hire Service"),
          value: "emergencyHireService",
        },
        {
          label: t("jobTypes.Skip Hire"),
          value: "skipHire",
        },
        {
          label: t("jobTypes.Grab Hire"),
          value: "grabHire",
        },
        {
          label: t("jobTypes.Cleaning Equipment Hire"),
          value: "cleaningEquipmentHire",
        },
        {
          label: t("jobTypes.Crane Hire"),
          value: "craneHire",
        },
        {
          label: t("jobTypes.Van / Car Hire"),
          value: "vanCarHire",
        },
        {
          label: t("jobTypes.Mini Bus Hire"),
          value: "miniBusHire",
        },
        {
          label: t("jobTypes.Skip Bag Hire"),
          value: "skipBagHire",
        },
        {
          label: t("jobTypes.Tool Hire"),
          value: "toolHire",
        },
        {
          label: t("jobTypes.Plant Hire / Sales"),
          value: "plantHireSales",
        },
        {
          label: t("jobTypes.Portable offices"),
          value: "portableOffices",
        },
        {
          label: t("jobTypes.Scaffolding Hire"),
          value: "scaffoldingHire",
        },
        {
          label: t("jobTypes.Marquee Hire"),
          value: "marqueeHire",
        },
        {
          label: t("jobTypes.Mini / Micro Digger Hire"),
          value: "miniMicroDiggerHire",
        },
        {
          label: t("jobTypes.Toilet / Portable Toilet"),
          value: "toiletPortableToilet",
        },
      ],
    },
    {
      id: 49,
      label: t("jobTypes.Weather Coatings"),
      value: "weatherCoatings",
      detail:
        "Weather coatings, often referred to as weather-resistant barriers or coatings, are protective layers applied to buildings or structures to shield them from environmental elements. Typically made of durable materials like silicone, acrylics, or elastomeric compounds, these coatings create a barrier against rain, wind, UV rays, and temperature fluctuations. ",
      icon: <TiWeatherPartlySunny />,
      children: [
        {
          label: t("jobTypes.Emergency Weather Coating Service"),
          value: "emergencyWeatherCoatingService",
        },
        {
          label: t("jobTypes.Roof Coatings"),
          value: "roofCoatings",
        },
      ],
    },
    {
      id: 50,
      label: t("jobTypes.Stonemason"),
      value: "stonemason",
      detail:
        "A stonemason is a skilled artisan who crafts structures and sculptures from stone, utilizing traditional techniques or modern tools. Meticulous in their work, stonemasons shape and arrange stones to create visually stunning and durable constructions, such as walls, arches, and monuments.",
      icon: <GiRuneStone />,
      children: [
        {
          label: t("jobTypes.Emergency Stonemason Service"),
          value: "emergencyStonemasonService",
        },
        {
          label: t("jobTypes.Memorial Gravestones Supplied"),
          value: "memorialGravestonesSupplied",
        },
        {
          label: t("jobTypes.Stone Floor Restoration"),
          value: "stoneFloorRestoration",
        },
      ],
    },
    {
      id: 51,
      label: t("jobTypes.Scaffolder"),
      value: "scaffolder",
      detail:
        "A scaffolder is a skilled professional responsible for erecting and dismantling scaffolding structures to facilitate construction, maintenance, or repair projects. Their role involves interpreting blueprints, assessing project requirements, and selecting appropriate materials for scaffold assembly. ",
      icon: <SiPyscaffold />,
      children: [
        {
          label: t("jobTypes.Emergency Scaffolder Service"),
          value: "emergencyScaffolderService",
        },
      ],
    },
    {
      id: 52,
      label: t("jobTypes.Fireplaces / Stoves"),
      value: "fireplacesStoves",
      detail:
        "Fireplaces and stoves serve as both functional heating sources and aesthetic focal points in homes. Crafted from various materials like stone, brick, or metal, they offer diverse design options. Traditional wood-burning models create a cozy ambiance with crackling flames, while modern gas or electric alternatives provide convenience.",
      icon: <MdFireplace />,
      children: [
        {
          label: t("jobTypes.Emergency Fireplaces / Stove Service"),
          value: "emergencyFireplacesStoveService",
        },
        {
          label: t("jobTypes.Gas Fire Installation"),
          value: "gasFireInstallation",
        },
        {
          label: t("jobTypes.Rayburn Servicing / Repair"),
          value: "rayburnServicingRepair",
        },
        {
          label: t("jobTypes.Stoves Installer"),
          value: "stovesInstaller",
        },
      ],
    },
    {
      id: 53,
      label: t("jobTypes.Carpets / Flooring"),
      value: "carpetsFlooring",
      detail:
        "Carpets and flooring play pivotal roles in interior design, offering both aesthetic appeal and functional benefits. Carpets, with their soft textures and diverse patterns, enhance comfort and warmth while muffling noise. They provide a customizable canvas to express personal style. ",
      icon: <GiRedCarpet />,
      children: [
        {
          label: t("jobTypes.Safety Flooring"),
          value: "safetyFlooring",
        },
        {
          label: t("jobTypes.Carpet Fitting"),
          value: "carpetFitting",
        },
        {
          label: t("jobTypes.Garage Flooring"),
          value: "garageFlooring",
        },
        {
          label: t("jobTypes.Laminate Flooring"),
          value: "laminateFlooring",
        },
        {
          label: t("jobTypes.Parquet Flooring"),
          value: "parquetFlooring",
        },
        {
          label: t("jobTypes.Ply Boarding"),
          value: "plyBoarding",
        },
        {
          label: t("jobTypes.Vinyl Flooring"),
          value: "vinylFlooring",
        },
        {
          label: t("jobTypes.Vinyl Floor Tiles"),
          value: "vinylFloorTiles",
        },
      ],
    },
    {
      id: 54,
      label: t("jobTypes.Insulation"),
      value: "insulation",
      detail:
        "Insulation is crucial for maintaining thermal comfort in buildings. It reduces heat transfer between the interior and exterior, enhancing energy efficiency. Common materials include fiberglass, foam board, and cellulose.",
      icon: <AiFillInsurance />,
      children: [
        {
          label: t("jobTypes.Flat Roof Insulation"),
          value: "flatRoofInsulation",
        },
        {
          label: t("jobTypes.Internal Solid Wall Insulation"),
          value: "internalSolidWallInsulation",
        },
        {
          label: t("jobTypes.Roof Joist Insulation"),
          value: "roofJoistInsulation",
        },
        {
          label: t("jobTypes.Cavity Wall Insulation"),
          value: "cavityWallInsulation",
        },
        {
          label: t("jobTypes.Solid Floor Insulation"),
          value: "solidFloorInsulation",
        },
      ],
    },
    {
      id: 55,
      label: t("jobTypes.Lofts / Loft Ladders"),
      value: "loftsLoftLadders",
      detail:
        "Lofts, commonly found in residential spaces, are elevated areas within a structure used for storage or additional living space. Loft ladders provide access to these elevated spaces, typically featuring a foldable design for space efficiency.",
      icon: <TbPoolOff />,
      children: [
        {
          label: t("jobTypes.Loft Floor Boarding"),
          value: "loftFloorBoarding",
        },
        {
          label: t("jobTypes.Loft Hatches"),
          value: "loftHatches",
        },
        {
          label: t("jobTypes.Loft Ladder Supplier"),
          value: "loftLadderSupplier",
        },
        {
          label: t("jobTypes.Loft Ladder Installer"),
          value: "loftLadderInstaller",
        },
      ],
    },
    {
      id: 56,
      label: t("jobTypes.Handyman"),
      value: "handyman",
      detail:
        "A handyman is a versatile tradesperson skilled in a broad range of home maintenance tasks. From fixing leaky faucets and repairing electrical issues to assembling furniture and painting, they tackle diverse projects. Their expertise extends to carpentry, plumbing, and basic HVAC repairs.",
      icon: <FaHandsHoldingCircle />,
      children: [
        {
          label: t("jobTypes.Flat Packs"),
          value: "flatPacks",
        },
        {
          label: t("jobTypes.Emergency Handyman Service"),
          value: "emergencyHandymanService",
        },
      ],
    },
    {
      id: 57,
      label: t("jobTypes.Glass"),
      value: "glass",
      detail:
        "Glass, a versatile material, exhibits a unique blend of transparency and strength. Composed primarily of silica, it undergoes a meticulous manufacturing process, involving melting, shaping, and cooling. Its transparency allows natural light to permeate spaces, fostering a sense of openness. ",
      icon: <FaHourglassHalf />,
      children: [
        {
          label: t("jobTypes.Glass Polishing"),
          value: "glassPolishing",
        },
        {
          label: t("jobTypes.Mirrors"),
          value: "mirrors",
        },
        {
          label: t("jobTypes.Glazier"),
          value: "glazier",
        },
        {
          label: t("jobTypes.Glass Splashbacks"),
          value: "glassSplashbacks",
        },
        {
          label: t("jobTypes.Glass Shower Screens"),
          value: "glassShowerScreens",
        },
        {
          label: t("jobTypes.Stained Glass Restoration"),
          value: "stainedGlassRestoration",
        },
      ],
    },
    {
      id: 58,
      label: t("jobTypes.Swimming Pools"),
      value: "swimmingPools",
      detail:
        "Swimming pools are recreational water bodies designed for swimming and other water-based activities. Typically, they consist of a basin filled with water, often treated with chemicals to maintain hygiene. Pools may be indoors or outdoors, private or public, and come in various sizes and shapes.",
      icon: <MdOutlinePool />,
      children: [
        {
          label: t("jobTypes.Hot Tubs / Spas"),
          value: "hotTubsSpas",
        },
        {
          label: t("jobTypes.Swimming Pool Maintenance"),
          value: "swimmingPoolMaintenance",
        },
        {
          label: t("jobTypes.Hot Tub Installation"),
          value: "hotTubInstallation",
        },
      ],
    },
    {
      id: 59,
      label: t("jobTypes.Motor Homes"),
      value: "motorHomes",
      detail:
        "Motor homes, also known as RVs (Recreational Vehicles), are versatile mobile dwellings equipped for travel and living. Combining a vehicle with living quarters, they offer a home on wheels, featuring bedrooms, kitchens, bathrooms, and often entertainment spaces. ",
      icon: <PiMotorcycleBold />,
      children: [
        {
          label: t("jobTypes.Motor Home Sales"),
          value: "motorHomeSales",
        },
      ],
    },
    {
      id: 60,
      label: t("jobTypes.Home Automation / Media"),
      value: "homeAutomationMedia",
      detail:
        "Home automation integrates smart devices and technology to enhance convenience and efficiency. Smart lighting, thermostats, and security systems can be controlled remotely, optimizing energy use. Media automation complements this by enabling seamless entertainment experiences.",
      icon: <SiCinema4D />,
      children: [
        {
          label: t("jobTypes.Home Cinema"),
          value: "homeCinema",
        },
        {
          label: t("jobTypes.Emergency Home Automation / Media Service"),
          value: "emergencyHomeAutomationMediaService",
        },
      ],
    },
    {
      id: 61,
      label: t("jobTypes.Towing / Transportation"),
      value: "towingTransportation",
      detail:
        "Towing involves the process of moving a disabled, improperly parked, or otherwise indisposed vehicle from one location to another using a specialized tow truck. These trucks, equipped with a lifting mechanism, securely transport cars, motorcycles, or even larger vehicles.",
      icon: <MdEmojiTransportation />,
      children: [
        {
          label: t("jobTypes.Transportation of Heavy Vehicles"),
          value: "transportationOfHeavyVehicles",
        },
        {
          label: t("jobTypes.Transportation of Light Vehicles"),
          value: "transportationOfLightVehicles",
        },
        {
          label: t("jobTypes.Vehicle Recovery"),
          value: "vehicleRecovery",
        },
      ],
    },
    {
      id: 62,
      label: t("jobTypes.Air Conditioning"),
      value: "airConditioning",
      detail:
        "Air conditioning is a technology that controls indoor temperature, humidity, and air quality. It typically involves a system of refrigeration, compression, and expansion of air to regulate the ambient conditions. The primary components include an evaporator, compressor, condenser, and expansion valve.",
      icon: <TbAirConditioning />,
      children: [
        {
          label: t("jobTypes.Air Conditioning Installation"),
          value: "airConditioningInstallation",
        },
      ],
    },
    {
      id: 63,
      label: t("jobTypes.Pest / Vermin Control"),
      value: "pestVerminControl",
      detail:
        "Pest and vermin control involves the management of unwanted organisms that adversely impact human activities, agriculture, or ecosystems. Integrated pest management (IPM) emphasizes environmentally sensitive strategies, combining biological, cultural, and chemical methods to minimize harm.",
      icon: <MdPestControl />,
      children: [
        {
          label: t("jobTypes.Wildlife management"),
          value: "wildlifeManagement",
        },
        {
          label: t("jobTypes.Wasp Nest Removal"),
          value: "waspNestRemoval",
        },
        {
          label: t("jobTypes.Emergency Pest / Vermin Control Service"),
          value: "emergencyPestVerminControlService",
        },
      ],
    },
    {
      id: 64,
      label: t("jobTypes.Architectural Services"),
      value: "architecturalServices",
      detail:
        "Architectural services encompass a comprehensive range of design, planning, and project management offerings to materialize clients' visions into built reality. Beginning with concept development and spatial planning, architects create detailed drawings, 3D models, and construction documents. ",
      icon: <MdOutlineArchitecture />,
      children: [
        {
          label: t("jobTypes.Planning Consultants"),
          value: "planningConsultants",
        },
        {
          label: t("jobTypes.Planning / Design"),
          value: "planningDesign",
        },
      ],
    },
    {
      id: 65,
      label: t("jobTypes.Groundworks"),
      value: "groundworks",
      detail:
        "Groundworks involve the initial phase of construction, encompassing excavation, foundation preparation, and site stabilization. This crucial stage ensures a solid base for structures. Excavation entails digging and leveling the ground, while foundation work establishes a stable support system.",
      icon: <FaCampground />,
      children: [
        {
          label: t("jobTypes.Under Pinning"),
          value: "underPinning",
        },
        {
          label: t("jobTypes.Piling"),
          value: "piling",
        },
      ],
    },
    {
      id: 66,
      label: t("jobTypes.Furniture Repair / Restoration"),
      value: "furnitureRepairRestoration",
      detail:
        "Furniture repair and restoration involve meticulous processes to revive or enhance the original condition of a piece. Skilled artisans assess damages, addressing issues like scratches, dents, or structural weaknesses. Stripping old finishes, they carefully match and apply new coatings, preserving the furniture's aesthetic integrity. ",
      icon: <GiSofa />,
      children: [
        {
          label: t("jobTypes.Upholstery"),
          value: "upholstery",
        },
        {
          label: t("jobTypes.Upholstery Repair"),
          value: "upholsteryRepair",
        },
        {
          label: t("jobTypes.Antique Restoration"),
          value: "antiqueRestoration",
        },
        {
          label: t("jobTypes.Garden Furniture Restoration"),
          value: "gardenFurnitureRestoration",
        },
      ],
    },
  ];

  return {
    jobTypes,
    updateDocument,
    loadingUpdating,
  };
}
