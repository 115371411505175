import { Button, Cascader, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProfileConstants from "utils/profileConstants";

export default function SearchMain() {
  const { jobTypes } = ProfileConstants();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState();

  const handleCascaderChange = (values) => {
    const updatedServices = [];
    values?.forEach((value) => {
      const category = jobTypes.find((item) => item.value === value[0]);
      const service = category?.children.find(
        (child) => child.value === value[1]
      );
      if (service) {
        updatedServices.push(service.value);
      } else {
        const children = category?.children.map((child) => child.value);
        updatedServices.push(...children);
      }
    });
    setSelectedValue(updatedServices);
  };

  const handleFind = () => {
    if (!selectedValue || selectedValue?.length === 0) {
      return message?.error(t("home.Please select job type"));
    }
    navigate(`/postajob?jobType=${selectedValue}`);
  };

  return (
    <div className="search_main">
      <div className="search_input">
        <div className="selector_jobTypes">
          <Cascader
            placeholder={t("home.Services")}
            style={{
              width: "100%",
            }}
            size="large"
            options={jobTypes}
            onChange={handleCascaderChange}
            multiple
            maxTagCount="responsive"
            showSearch={true}
          />
        </div>
        <div className="button_search">
          <Button
            htmlType="submit"
            className="btn_find"
            type="primary"
            onClick={() => handleFind()}
          >
            {t("home.Find a professional")}
          </Button>
        </div>
      </div>
    </div>
  );
}
