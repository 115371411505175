import { Avatar, Button, Divider, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProfileConstants from "utils/profileConstants";

const { Paragraph } = Typography;
const { Text } = Typography;

export default function SomeJobs() {
  const { jobTypes } = ProfileConstants();

  const filterValues = ["plumber", "plasterer", "roofer"];
  const filteredJobTypes = jobTypes?.filter((job) =>
    filterValues?.includes(job.value)
  );
  const [ellipsis, setEllipsis] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="categories">
      <div className="hiring">
        <div className="main-hiring">
          <Typography className="title1">
            {t("home.Prepare for winter")}
          </Typography>
          <div className="hiring_three">
            {filteredJobTypes?.map((item) => (
              <div className="hiring_inner" key={item?.id}>
                <div className="first_hiring">
                  <div className="map_2">
                    <img
                      src={item?.img}
                      width="100%"
                      height="200px"
                      style={{ borderRadius: "10px" }}
                      alt=""
                    />
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Avatar shape="square" src={item?.icon} />
                      <Typography className="title3">{item?.label}</Typography>
                    </div>

                    <Paragraph
                      ellipsis={
                        ellipsis
                          ? {
                              rows: 2,
                              expandable: true,
                              symbol: "more",
                            }
                          : false
                      }
                      className="text3"
                    >
                      {item?.description}
                    </Paragraph>
                    <Divider />

                    <Button
                      type="link"
                      onClick={() => {
                        navigate(`/viewDetailsJob?jobType=${item?.value}`, {
                          state: {
                            label: item?.label,
                            detail: item?.detail,
                            children: item?.children,
                          },
                        });
                      }}
                    >
                      <p style={{ textDecoration: "underline" }}>
                        All information about {item?.label}
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
