import { db } from "config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getProjects, getProjectsData } from "./helpers";

/**
 * Will take a collection path and id, return a sindle data object
 * @param {String} queryPath
 * @param {String} id
 * @returns Data Object
 */
export const getData = async (queryPath, id) => {
  const dataRef = doc(db, queryPath, id);
  const docSnap = await getDoc(dataRef);

  if (docSnap.exists()) {
  } else {
    console.log("No such document!");
  }
  return docSnap.data();
};
/**
 * will take a collection path as string and return data
 * @param {String} queryPath
 * @returns Array of data
 */
export const getAllData = async (queryPath) => {
  const dataArr = [];
  const dataRef = collection(db, queryPath);
  const dataQuery = query(dataRef);
  const querySnapshot = await getDocs(dataQuery);
  querySnapshot.forEach((doc) => {
    dataArr.push(doc.data());
  });
  return dataArr;
};

// export const getFilteredData = async (queryPath, filters) => {
//   const dataArr = [];
//   const dataRef = collection(db, queryPath);
//   const dataQuery = query(
//     dataRef
//   );
//   const querySnapshot = await getDocs(dataQuery);
//   querySnapshot.forEach(async (doc) => {
//     dataArr.push(doc.data());
//   }
//   );
//   return dataArr;
// }

export const getFilteredData = async (queryPath, filters) => {
  const dataArr = [];
  const dataRef = collection(db, queryPath);
  const dataQuery = query(dataRef, ...filters);
  return new Promise(async (resolve, reject) => {
    const querySnapshot = await getDocs(dataQuery);
    querySnapshot.forEach(async (doc) => {
      const data = doc.data();
      const uid = doc.data().uid;
      const projectsData = await getProjectsData("projects", uid);
      let sum = 0;
      projectsData.forEach((item) => {
        if (item.buyerRating !== undefined) {
          sum += item?.buyerRating;
        }
      });
      const average = sum / projectsData.length;
      let setAverage = average.toFixed(1);
      const avrge = !isNaN(setAverage) ? setAverage : null;
      const object = { ...data, projectsData, averageRating: avrge };
      dataArr.push(object);
      if (dataArr.length === querySnapshot.size) {
        resolve(dataArr);
      }
    });
  });
};

export const getPaymentsData = async (queryPath, filters) => {
  const dataRef = collection(db, queryPath);
  const dataQuery = query(dataRef, ...filters);
  return new Promise(async (resolve, reject) => {
    const querySnapshot = await getDocs(dataQuery);
    const dataArr = [];
    querySnapshot.forEach(async (doc) => {
      const data = doc.data();
      const projectId = doc.data().projectId;
      const promise = await getProjects("projects", projectId).then(
        (projectData) => {
          const projectTitle = projectData?.title;
          const object = { ...data, projectTitle: projectTitle };
          return object;
        }
      );
      dataArr.push(promise);
    });
    const localInfo = localStorage.getItem("info");
    const info = JSON.parse(localInfo);
    const uid = info?.userId;
    if (dataArr.length === querySnapshot.size) {
      const filteredData = dataArr.filter(
        (item) => item.senderId === uid || item.recieverId === uid
      );
      resolve(filteredData);
    }
  });
};

export const getListing = async (queryPath, filters) => {
  const dataRef = collection(db, queryPath);
  const dataQuery = query(dataRef, ...filters);
  return new Promise(async (resolve, reject) => {
    const querySnapshot = await getDocs(dataQuery);
    const arr = [];
    querySnapshot.forEach(async (doc) => {
      const data = doc.data();
      const uid = doc.data().uid;
      const projectsData = await getProjectsData("projects", uid);
      let sum = 0;
      projectsData.forEach((item) => {
        if (item.buyerRating !== undefined) {
          sum += item?.buyerRating;
        }
      });
      const average = sum / projectsData.length;
      let setAverage = average.toFixed(1);
      const avrge = !isNaN(setAverage) ? setAverage : null;
      const object = { ...data, projectsData, averageRating: avrge };
      arr.push(object);
    });
    if (arr.length === querySnapshot.size) {
      resolve(arr);
    }
  });
};

export const getProfessionalDetails = async (queryPath, id) => {
  const dataRef = doc(db, queryPath, id);
  return new Promise(async (resolve, reject) => {
    const docSnap = await getDoc(dataRef);
    console.log(docSnap.data(), "docSnap");
    const data = docSnap.data();
    let uid = docSnap.data().uid;
    const projectsData = await getProjectsData("projects", uid);
    let sum = 0;
    let arr = [];
    projectsData.forEach(async (project) => {
      let reviewerId = project.participants[1];
      const reviewerData = await getData("users", reviewerId);
      const combine = { ...project, reviewerName: `${reviewerData?.username}` };
      arr.push(combine);
    });
    projectsData.forEach((project) => {
      if (project?.rating !== undefined) {
        sum += project.rating;
      }
    });
    const average = sum / projectsData.length;
    const avrge = !isNaN(average) ? average : null;
    if (arr.length === projectsData.length) {
      console.log(arr, "arr");
      const object = { ...data, projectsData: arr, averageRating: avrge };
      resolve(object);
    }
  });
};

export const fetchMessages = async (path, id) => {
  const dataArr = [];
  const docRef = doc(db, path, id);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    // const docData = docSnapshot.data();
    const subCollectionRef = collection(docRef, "messages");
    const subDocsSnapshot = await getDocs(subCollectionRef);
    subDocsSnapshot.docs.map((doc) => {
      return dataArr.push(doc.data());
    });
  }
  return dataArr;
};

export const getSubdocument = async (
  collectionName,
  documentId,
  subcollectionName,
  subDocumentId
) => {
  console.log(
    collectionName,
    documentId,
    subcollectionName,
    subDocumentId,
    "sub doc"
  );
  const subDocRef = doc(
    db,
    `${collectionName}`,
    `${documentId}`,
    `${subcollectionName}`,
    `${subDocumentId}`
  );
  const subDocSnapshot = await getDoc(subDocRef);
  if (subDocSnapshot.exists()) {
    const subDocData = subDocSnapshot.data();
    console.log(subDocData, "ddddd");
  }
};
export const getDocumentData = (id) => {
  return new Promise((resolve, reject) => {
    const dataArr = [];
    const docRef = collection(db, "chats", id, "messages");
    const queryRef = query(docRef, orderBy("createdAt", "desc"), limit(1));
    const unsubscribe = onSnapshot(
      queryRef,
      (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });

        resolve({ unsubscribe, dataArr });
      },
      reject
    );
  });
};

export const getProfessionalData = async () => {
  let dataArr = [];
  const docRef = collection(db, "users");
  const queryRef = query(
    docRef,
    where("role", "==", "professional"),
    orderBy("createdAt", "desc"),
    limit(10)
  );
  const snapshot = await getDocs(queryRef);
  snapshot.forEach(async (doc) => {
    const data = doc.data();
    const uid = doc.data().uid;
    const projectsData = await getProjectsData("projects", uid);
    let sum = 0;
    projectsData.forEach((project) => {
      if (project?.rating !== undefined) {
        sum += project.rating;
      }
    });
    const average = sum / projectsData?.length;
    const avrge = !isNaN(average) ? average : null;
    const object = { ...data, projectsData, averageRating: avrge };
    dataArr.push(object);
  });
  return dataArr;
};

export const getQuerYdata = async () => {
  const q = query(collection(db, "projects"), where("accept", "==", true));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {});
};
