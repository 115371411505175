import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authUsers from "./authSlice";
import chat from "./chatSlice";
import language from "./translationSlice"

const combineReducers = {
  authUsers,
  chat,
  language,
};

const store = configureStore({
  reducer: combineReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
