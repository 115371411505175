import { useEffect, useState } from "react";
import { db } from "config";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { getProjectsData } from "services/helpers";

const useHomeListing = (path) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const dataRef = collection(db, path);
    const queryRef = query(
      dataRef,
      where("role", "==", "professional"),
      orderBy("createdAt", "desc"),
      limit(10)
    );
    const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
      if (!querySnapshot.empty) {
        setData([]);
        let arr = [];
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          const uid = doc.data().uid;
          const projectsData = await getProjectsData("projects", uid);
          let sum = 0;
          projectsData.forEach((item) => {
            if (item.buyerRating !== undefined) {
              sum += item?.buyerRating;
            }
          });
          const average = sum / projectsData.length;
          let setAverage = average.toFixed(1);
          const avrge = !isNaN(setAverage) ? setAverage : null;
          const object = { ...data, projectsData, averageRating: avrge };
          arr.push(object);
          if (arr.length === querySnapshot.size) {
            setData([...arr]);
          }
          setLoading(false);
        });
      }
    });
    return unsubscribe;
  };
  useEffect(() => {
    getData(); // eslint-disable-next-line
  }, [path]);
  return { data, loading };
};

export default useHomeListing;
