import { Avatar, Button, Col, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProfileConstants from "utils/profileConstants";

export default function FindProfessional() {
  const navigate = useNavigate();
  const ellipsis = true;
  const { t } = useTranslation();
  const { jobTypes } = ProfileConstants();
  const [showAllJobTypes, setShowAllJobTypes] = useState(false);

  const toggleShowAllJobTypes = () => {
    setShowAllJobTypes(!showAllJobTypes)
  };

  return (
    <div className="categories" style={{ backgroundColor: "#f9f8f6" }}>
      <div className="hiring">
        <div className="main-hiring">
          <Typography className="title1">
            {t("home.Find professionals for every job")}
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {jobTypes?.slice(0, showAllJobTypes ? jobTypes.length : Math.ceil(jobTypes.length / 2)).map((item) => {
              return (
                <Col lg={8} key={item.id}>
                  <div className="hiring_inner">
                    <div className="first_hiring">
                      <div className="map_2">
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <Avatar
                            style={{ color: 'black', fontSize: '30px' }}
                            shape="square"
                            src={item.icon}
                          />
                          <Typography.Title
                            className="title3"
                            level={3}
                            style={
                              ellipsis
                                ? {
                                  width: 280,
                                }
                                : undefined
                            }
                            ellipsis={
                              ellipsis
                                ? {
                                  tooltip: `${item.label}`,
                                }
                                : false
                            }
                          >
                            {item.label}
                          </Typography.Title>
                        </div>
                        <Button
                          type="link"
                          onClick={() => {
                            navigate(`/viewDetailsJob?jobType=${item.value}`, {
                              state: {
                                label: item.label,
                                detail: item.detail,
                                children: item.children,
                              },
                            });
                          }}
                        >
                          <Typography.Title
                            level={3}
                            className="Title1"
                            style={
                              ellipsis
                                ? {
                                  width: 200,
                                }
                                : undefined
                            }
                            ellipsis={
                              ellipsis
                                ? {
                                  tooltip: `${item.label}`,
                                }
                                : false
                            }
                          >
                            {t('home.All information about')} {item.label}
                          </Typography.Title>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
            {!showAllJobTypes && (
              <div style={{ width: "100%", textAlign: "center", marginTop: '10px' }}>
                <Button
                  type="primary"
                  onClick={toggleShowAllJobTypes}
                >
                  See More
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
