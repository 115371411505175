import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	value: null
}
export const translationSlice = createSlice({
	name: 'language',
	initialState,
	reducers: {
		setLanguange: (state, { payload }) => {
			state.value = payload
		}
	}
})
export const {
    setLanguange
} = translationSlice.actions

export default translationSlice.reducer