import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ConfigProvider } from "antd";
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#5C4033",
      },
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);
