import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Protected from "./protected";
import Layout from "components/layout";
import PageNotFound from "pages/pageNotFound";
import LazyLoader from "./lazyLoader";
import { isAuthenticated } from "./helpers";
import { loader as homelistingLoader } from "../pages/home";
import Completion from "components/payment/completion";
import CancelledScreen from "components/payment/cancelledScreen";

const router = (info) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          index
          element={<LazyLoader path="home" />}
          loader={homelistingLoader}
        />
        <Route element={<Layout />}>
          <Route
            path="support"
            element={<LazyLoader path="home/supportPage" />}
          />
          <Route
            path="payment"
            element={<LazyLoader path="client/stripe" />}
          />

          <Route
            path="completion"
            element={<Completion />}
          />
          <Route
            path="cancelled"
            element={<CancelledScreen />}
          />

          <Route
            path="support/:id"
            element={<LazyLoader path="home/supportScreenDetaiPage" />}
          />
          <Route
            path="profiles"
            element={<LazyLoader path="common/proffesionals" />}
          />
          <Route
            path="viewDetailsJob"
            element={<LazyLoader path="common/viewDetailsJob" />}
          />
          <Route path="jobs" element={<LazyLoader path="common/jobs" />} />
          <Route
            path="subscription"
            element={<LazyLoader path="subscription/subscriptionPage" />}
          // loader={paymentLoader}
          // errorElement={"Something Went Wrong"}
          />
          <Route
            path="cardDetails"
            element={<LazyLoader path="subscription/cardPage" />}
          // loader={paymentLoader}
          // errorElement={"Something Went Wrong"}
          />
          <Route
            path="howitworks"
            element={<LazyLoader path="home/howItWorks" />}
          />
          <Route
            path="contactUs"
            element={<LazyLoader path="home/contactUs" />}
          />
          <Route
            path="privacyPolicy"
            element={<LazyLoader path="home/privacyPolicy" />}
          />
          <Route
            path="termsAndConditions"
            element={<LazyLoader path="home/termsAndConditions" />}
          />
          <Route
            path="postajob"
            element={<LazyLoader path="stepper/stepper" />}
          // loader={paymentLoader}
          // errorElement={"Something Went Wrong"}
          />
          <Route path="client" element={<Protected />}>
            <Route
              index
              element={<Navigate to="/client/workers-list" replace />}
            />
            <Route
              path="workers-list"
              element={<LazyLoader path="client/professionalsListing" />}
            // loader={professionalListingLoader}
            // errorElement={"Something Went Wrong"}
            />
            <Route
              path="workers-list/:uid"
              element={<LazyLoader path="client/professionalDetailsPage" />}
              errorElement={"Something Went Wrong"}
            />
            <Route
              path="dashboard"
              element={<LazyLoader path="dashboard/jobsPage" />}
            // errorElement={"Something Went Wrong"}
            // loader={jobsListingLoader}
            />
            <Route
              path="message"
              element={<LazyLoader path="chat/chatPage" />}
            // loader={userProfessionalLoader}
            // errorElement={"Something Went Wrong"}
            />
            {/* <Route
              path="payments"
              element={<LazyLoader path="payments/paymentClientPage" />}
            // loader={paymentLoader}
            // errorElement={"Something Went Wrong"}
            /> */}

          </Route>
          <Route path="professional" element={<Protected />}>
            <Route
              index
              element={<Navigate to="/professional/user-profile" />}
            />
            <Route
              path="user-profile"
              element={<LazyLoader path="professional/userProfile" />}
              // loader={userProfileLoader}
              errorElement={"Something Went Wrong"}
            />
            <Route
              path="jobslist"
              element={<LazyLoader path="dashboard/jobsPage" />}
            // errorElement={"Something Went Wrong"}
            // loader={jobsListingLoader}
            />
            <Route
              path="message"
              element={<LazyLoader path="chat/chatPage" />}
            // loader={userProfessionalLoader}
            // errorElement={"Something Went Wrong"}
            />
            {/* <Route
              path="payments"
              element={<LazyLoader path="payments/payment" />}
            // loader={paymentLoader}
            // errorElement={"Something Went Wrong"}
            /> */}
          </Route>
        </Route>
        <Route
          path="login"
          element={<LazyLoader path="auth/login" />}
          loader={async () => await isAuthenticated(info)}
        />

        <Route
          path="register"
          element={<LazyLoader path="auth/register" />}
          loader={async () => await isAuthenticated(info)}
        />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    )
  );

const Index = () => {
  const localInfo = localStorage.getItem("info");
  const info = JSON.parse(localInfo);
  return <RouterProvider router={router(info)} />;
};

export default Index;
