import React from "react";
import logo from "../asset/logo2.png";
import { Row, Col, Typography } from "antd";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { CiTwitter } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import useHome from "utils/home";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const { Text } = Typography;

function HomeFooter() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { footerItem, footerItems } = useHome();
  return (
    <>
      <div className="main_footer">
        <Row className="">
          <Col xs={24} md={12} lg={8} className="col_design">
            <img src={logo} width="80px" height="80px" alt="" />
            <div>
              <FiFacebook className="icons" />
              <FaInstagram className="icons" />
              <CiTwitter className="icons" />
            </div>
          </Col>
          <Col xs={24} md={12} lg={8} className="col_design">
            <Text
              style={{ cursor: "pointer" }}
              className="fot-text"
              onClick={() => navigate("/support")}
            >
              {t("home.footer.Support")}
            </Text>
            {footerItems.map((item) => {
              return (
                <>
                  <div key={item?.id} style={{ cursor: "pointer" }}>
                    <Text className="items">{item?.title}</Text>
                  </div>
                </>
              );
            })}
          </Col>
          <Col xs={24} md={12} lg={8} className="col_design">
            <Text className="fot-text">{t("home.footer.About Company")}</Text>
            {footerItem.map((item) => {
              return (
                <>
                  <div
                    key={item?.id}
                    style={{ cursor: "pointer", color: "white" }}
                  >
                    <Text className="items">{item?.title}</Text>
                  </div>
                </>
              );
            })}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HomeFooter;
