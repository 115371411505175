import React from 'react'

export default function CancelledScreen() {
    return (
        <div style={{
            width: '100%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '400px',
            borderRadius: '20px'
        }}>
            <div style={{ width: '50%', background: "white", padding: '20px' }}>
                <h1>Oops ! your payment is cancelled</h1>
            </div>
        </div >
    )
}
