
const Footer = () => {
  const date = new Date();
  return (
    <>
      <footer className="footer_text">
        Copyright © 724.one {date?.getFullYear()} All Rights Reserved
      </footer>
    </>
  )
};

export default Footer;
