import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "config";
import { message } from "antd";
import moment from "moment/moment";

export const uploadFile = (path, file) => {
  new Promise((resolve, reject) => {
    const uniqueKey = new Date().getUTCMilliseconds();
    const storageReference = ref(storage, `${path}/${uniqueKey}-${file.name}`);
    const task = uploadBytesResumable(storageReference, file);
    const taskProgress = () => { };
    const taskError = reject;
    const taskCompleted = async () => {
      await getDownloadURL(task.snapshot.ref)
        .then((downloadURL) => {
          resolve(downloadURL);
          alert("uploaded successfully!");
        })
        .catch((error) => {
          reject(error);
          alert(error);
        });
    };
    task.on("state_changed", taskProgress, taskError, taskCompleted);
  });
};
export const uploadDocuments = (path, file) => {
  return new Promise((resolve, reject) => {
    const uniqueKey = new Date().getUTCMilliseconds();
    const storageReference = ref(storage, `${path}/${uniqueKey}-${file.name}`);
    const task = uploadBytesResumable(storageReference, file);
    const taskProgress = () => { };
    const taskError = reject;
    const taskCompleted = async () => {
      await getDownloadURL(task.snapshot.ref)
        .then((downloadURL) => {
          resolve(downloadURL)
          message?.success("Your file is uplaoded")
        })
        .catch((error) => {
          reject(error);
          alert(error);
        });
    };
    task.on("state_changed", taskProgress, taskError, taskCompleted);
  });
};
export const updateDocument = async (
  path,
  id,
  username,
  email,
  phoneNumber,
  headline,
  address,
  additionalAddress,
  services,
  about,
  addressObject,
  servicesStrings
) => {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, {
    username: username,
    email: email,
    phoneNumber: phoneNumber,
    headline: headline,
    address: address ? address : '',
    services: services,
    about: about,
    additionalAddress: additionalAddress ? additionalAddress : "",
    addressObject: addressObject,
    servicesStrings: servicesStrings,
  })
    .then(() => {
      message.success("your profile is updated !");
    })
    .catch((error) => {
      alert(error?.message);
    });
};
export const updatePicture = async (path, id, imgURL) => {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, {
    photoURL: imgURL,
  })
    .then((res) => {
      message.success("your photo is uploaded successfully !");
    })
    .catch((error) => {
      alert(error?.message);
    });
};
export const updateProject = async (path, id, status, object) => {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, {
    ...object,
    status: `${status}`,
    isBuyerRating: false,
    isSellerRating: false,
  }).catch((error) => {
    alert(error);
  });
};

export const updateChatStatus = async (chatDocId) => {
  const docRef = doc(db, "chats", chatDocId);
  await updateDoc(docRef, {
    isRead: false,
  })
    .then((res) => { })
    .catch((error) => {
      console.log(error);
    });
};
export const updateChat = async (chatDocId) => {
  const docRef = doc(db, "chats", chatDocId);
  await updateDoc(docRef, {
    isRead: true,
  }).catch((error) => {
    alert(error);
  });
};

export const updateSubscription = async (path, id, price) => {
  const insuranceURL = localStorage.getItem("insurance")
  const commerceURL = localStorage.getItem("commerceCertificate")
  const docRef = doc(db, path, id);
  const currentDate = moment().format("DD-MM-YYYY");
  const oneMonthFromNow = moment(currentDate, "DD-MM-YYYY")
    .add(1, "months")
    .format("DD-MM-YYYY");

  // console.log(oneMonthFromNow, "oneMonthFromNow");
  // const oneYearFromNow = moment(currentDate, "DD-MM-YYYY")
  //   .add(1, "year")
  //   .format("DD-MM-YYYY");
  // const sixMonthsFromNow = moment(currentDate, "DD-MM-YYYY")
  //   .add(6, "months")
  //   .format("DD-MM-YYYY");
  // const endDate = payment === "half_year"
  //   ? sixMonthsFromNow
  //   : payment === "full_year"
  //     ? oneYearFromNow
  //     : payment === "one_month"
  //       ? oneMonthFromNow
  //       : null;

  const endDate = oneMonthFromNow

  await updateDoc(docRef, {
    isSubscription: true,
    insuranceDocumentURL: insuranceURL ? insuranceURL : "",
    commerceCertificateURL: commerceURL ? commerceURL : "",
    subscription: {
      type: "paid",
      price: Number(price),
      startDate: currentDate,
      endDate: endDate,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    },
  })
    .then(() => {
      message?.success("payment successfull");
      window.location.replace("/professional/user-profile");
    })
    .catch((error) => {
      alert(error);
    });
};
export const updateSubscriptionAuth = async (path, id) => {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, {
    isSubscription: false,
  });
};
export const updateProjectRating = async (path, id, rating, review) => {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, {
    buyerRating: rating,
    buyerReview: review,
    isBuyerRating: true,
  })
    .then(() => {
      message.success("your rating is successfully done !");
    })
    .catch((error) => {
      alert(error);
    });
};
export const updateProfessionalRating = async (path, id, rating, review) => {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, {
    sellerRating: rating,
    sellerReview: review,
    isSellerRating: true,
  })
    .then(() => {
      message.success("your rating is successfully done !");
    })
    .catch((error) => {
      alert(error);
    });
};
export const getProjectsData = async (queryPath, uid) => {
  const dataArr = [];
  const dataRef = collection(db, queryPath);
  const dataQuery = query(
    dataRef,
    where("status", "==", "completed"),
    where("isBuyerRating", "==", true),
    where("participants", "array-contains", `${uid}`)
  );
  const querySnapshot = await getDocs(dataQuery);
  querySnapshot.forEach((doc) => {
    dataArr.push(doc.data());
  });
  return dataArr;
};
export const getProjects = async (queryPath, id) => {
  const dataRef = doc(db, queryPath, id);
  const dataQuery = query(dataRef);
  const docSnap = await getDoc(dataQuery);
  return docSnap.data();
};

export const addNotificationClient = async (notification, clientId, title) => {
  await addDoc(collection(db, "notifications"), {
    notification: notification,
    projectTitle: title,
    userId: `${clientId}`,
    isRead: false,
    createdAt: serverTimestamp(),
  }).then(async (value) => {
    const docRef = doc(db, "notifications", `${value?.id}`);
    await updateDoc(docRef, {
      id: `${value?.id}`,
    });
  });
};

export const addNotificationProffessional = async (
  notification,
  professionalId,
  title
) => {
  await addDoc(collection(db, "notifications"), {
    notification: notification,
    projectTitle: title,
    userId: `${professionalId}`,
    isRead: false,
    createdAt: serverTimestamp(),
  }).then(async (value) => {
    const docRef = doc(db, "notifications", `${value?.id}`);
    await updateDoc(docRef, {
      id: `${value?.id}`,
    });
  });
};
