import { redirect } from "react-router-dom";

export const isAuthenticated = async (info) => {
  if (info) throw redirect(`/${info?.role}`);
  return null;
};

export const handleLocalStorage = (pathname) => {
  let prevPathway = JSON.parse(localStorage.getItem("pathway")) || [];
  let newPathway = [...prevPathway, pathname];
  localStorage.setItem("pathway", JSON.stringify(newPathway));
};

export const findCompatiblePath = ({ path, role }) => {
  const pathsArr = path?.split("/");
  const parentPath = pathsArr?.length > 1 ? pathsArr[1] : "/";

  // Not logged in
  if (!role) return "/login";

  // Logged in but not permitted
  if (parentPath !== role) return parentPath;

  // Logged in and permitted
  if (parentPath === role) return path;

  // default case
  return "/";
};
