import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { auth } from "config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { getData } from "services/getData";
import { updateSubscriptionAuth } from "services/helpers";
import { usersData } from "services/users";
import { END_POINT, ROLES } from "utils/constants";

export const registerUser = createAsyncThunk(
  "auth/signup",
  async (
    { username, email, password, role, navigate, price },
    { rejectWithValue }
  ) => {
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, {
        displayName: role,
      });
      const info = {
        role: role,
        userId: auth.currentUser.uid,
        email: email,
      };
      localStorage.setItem("info", JSON.stringify(info));
      await usersData(username, email, password, user?.user?.uid, role, price);
      return { userId: user?.user?.uid, role: role };
    } catch (error) {
      let message = "Something went wrong";
      if (error.message) message = error.message;
      alert(error?.message);
      return rejectWithValue(message);
    }
  }
);
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password, role, navigate }, { rejectWithValue }) => {
    try {
      const loginRes = await signInWithEmailAndPassword(auth, email, password);
      const userId = loginRes.user.uid;
      const info = {
        role: role,
        userId: loginRes.user.uid,
        email: email,
      };
      localStorage.setItem("info", JSON.stringify(info));
      const singleDoc = await getData("users", userId);
      if (singleDoc?.role !== role) {
        message.info("select your designation correct !");
        signOut(auth)
          .then(() => {
            localStorage.clear();
            navigate("/login");
          })
          .catch(() => { });
      } else if (singleDoc?.role === ROLES?.PROFESSIONAL) {
        try {
          const response = await axios.get(`${END_POINT}/stripe/subscription-status?email=${email}`)
          if (response?.data?.status === "unactive") {
            updateSubscriptionAuth("users", userId)
          }
        } catch (error) {
          console.error('Error fetching subscription status:', error);
        }
        // TODO: Need to replace it with navigate
        window.location.replace("/professional/user-profile")
      } else if (singleDoc?.role === ROLES?.CLIENT) {
        window.location.replace("/client/workers-list");
      }
      return { userId, role, email };
    } catch (error) {
      let message = "Something went wrong";
      if (error.message) message = error.message;
      alert(error?.message);
      return rejectWithValue(message);
    }
  }
);

export const checkAuth = createAsyncThunk(
  "auth/checkAuth",
  async (data, { rejectWithValue }) => {
    try {
      if (data) {
        return { ...data };
      } else {
        return null;
      }
    } catch (error) {
      let message = "Something went wrong";
      if (error.message) message = error.message;
      alert(error?.message);
      return rejectWithValue(message);
    }
  }
);
