import rooficon from "./roofingIcon.svg";
import plasterIcon from "./plasteringIcon.svg";
import plumberIcon from "./plumbingIcon.svg";
import surveyingIcon from "./surveyingIcon.svg"
import bathroomIcon from "./bathroomIcon.svg";
import electricityIcon from "./electricityIcon.svg"
import bedroomIcon from "./bedroomIcon.svg"
import extensionIcon from "./extensionIcon.svg";
import floorIcon from "./flooring-tiles-Icon.svg";
import gardenIcon from "./gardening-outdoors-icon.svg";
import paintIcon from "./paintIcon.svg";
import kitchenIcon from "./kitchenIcon.svg";
import windowIcon from "./windows-doors-icon.svg";
import heatingIcon from "./heatingIcon.svg";
import airIcon from "./air-conditioning-icon.svg";
import upholsteryIcon from "./upholsteryIcon.svg";
import demolitionIcon from "./demolitionIcon.svg";
import insulationIcon from "./insulationIcon.svg";
import carpenteryIcon from "./carpentryIcon.svg";
import cleaningIcon from "./cleaningIcon.svg";
import renewableIcon from "./renewableIcon.svg";
import vhicleIcon from "./vehicleIcon.svg";
import houseIcon from "./movingIcon.svg";
import securityIcon from "./securityIcon.svg";

export const images = {
    carpenter: require('./carpenter.jpg'),
    electrician: require('./electrician.jpg'),
    kitchen: require('./kitchens.jpg'),
    plastering: require('./plaster.jpg'),
    plumbing: require('./plumber.jpg'),
    roofs: require('./roofs.jpg'),
    plasteringIcon: plasterIcon,
    electricityIcon: electricityIcon,
    plumbingIcon: plumberIcon,
    roofsIcon: rooficon,
    generalJobs: require("./generalJobs.avif"),
    bathroom: require("./bathroom.avif"),
    electricity: require("./electrician.jpg"),
    surveyingIcon: surveyingIcon,
    bathroomIcon: bathroomIcon,
    bedroomIcon: bedroomIcon,
    extensionIcon: extensionIcon,
    floorIcon: floorIcon,
    gardenIcon: gardenIcon,
    paintIcon: paintIcon,
    kitchenIcon: kitchenIcon,
    windowIcon: windowIcon,
    heatingIcon: heatingIcon,
    airIcon: airIcon,
    upholsteryIcon: upholsteryIcon,
    demolitionIcon: demolitionIcon,
    insulationIcon: insulationIcon,
    carpenteryIcon: carpenteryIcon,
    cleaningIcon: cleaningIcon,
    renewableIcon: renewableIcon,
    vhicleIcon: vhicleIcon,
    houseIcon: houseIcon,
    securityIcon: securityIcon,
}