import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	value: null,
	status: null,
	photoUrl: null,
	paymentDetails: null,
	username: null,
	docId: null,
	paymentValue: null,
	firebaseURL: null
}
export const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		getId: (state, { payload }) => {
			state.value = payload
		},
		getStatus: (state, { payload }) => {
			state.status = payload
		},
		getPhotoUrl: (state, { payload }) => {
			state.photoUrl = payload
		},
		setPayment: (state, { payload }) => {
			state.paymentDetails = payload
		},
		setUserName: (state, { payload }) => {
			state.username = payload
		},
		setPaymentValue: (state, { payload }) => {
			state.paymentValue = payload
		},
		setFirebaseURL: (state, { payload }) => {
			const { documentType, URL } = payload;
			state.firebaseURL[documentType] = URL;
		},
	}
})
export const {
	getId,
	getStatus,
	getPhotoUrl,
	setPayment,
	setUserName,
	setFirebaseURL
} = chatSlice.actions

export default chatSlice.reducer