import { useEffect, useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { findCompatiblePath, handleLocalStorage } from "./helpers";
import Spinner from "components/common/spinner";

const Protected = () => {
  const { pathname } = useLocation();
  // const { userInfo } = useSelector((state) => state.authUsers);

  const localInfo = localStorage.getItem("info");
  const info = JSON.parse(localInfo);
  // TODO: Need to figure out why directly navigating to some route not works
  useEffect(() => {
    handleLocalStorage(pathname);
  }, [pathname]);

  const path = useMemo(
    () =>
      findCompatiblePath({
        path: pathname,
        role: info?.role,
      }), // eslint-disable-next-line
    [info]
  );
  if (!path) return <Spinner />;
  return info ? <Outlet /> : <Navigate to={path} />;
};

export default Protected;
