import { Button, Select, Avatar, Popover, Badge, ConfigProvider } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../asset/logo2.png";
import { Languages, ROLES } from "utils/constants";
import { BellOutlined } from "@ant-design/icons";
import { setLanguange } from "redux/translationSlice";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import useNotification from "hooks/useNotification";
import Notification from "components/common/notification";
import ResponsiveDrawer from "./drawer";
import ClientDropDown from "./clientDropdown";
import ProfessionalDropdown from "./professionalDropdown";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data } = useNotification("notifications");
  const localInfo = localStorage.getItem("info");
  const info = JSON.parse(localInfo);
  const content = (
    <div>
      <Notification />
    </div>
  );
  const filtered = data.filter((el) => {
    return el?.isRead === false;
  });

  const handleLanguage = (e) => {
    if (e === "English") {
      dispatch(setLanguange("en"));
      i18n.changeLanguage("en");
    } else if (e === "Dutch") {
      dispatch(setLanguange("nl"));
      i18n.changeLanguage("nl");
    }
  };

  return (
    <div style={{ height: "70px" }}>
      <header>
        <div className="logo">
          <div
            onClick={() => navigate("/")}
            style={{
              marginLeft: "10px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <Avatar shape="square" size={60} src={logo} />
          </div>
          <div className="navbar_main">
          {info && (
            <>
              <div
                className="notification"
                // onClick={() => setBellClicked(true)}
              >
                <Badge count={filtered?.length} dotSize={3}>
                  <Popover
                    placement="bottom"
                    className="notifications"
                    style={{ border: "1px solid black" }}
                    title={<b>{t("common.Notifications")}</b>}
                    content={content}
                    trigger="click"
                  >
                    <BellOutlined
                      style={{ fontSize: "25px", color: "#60331d" }}
                    />
                  </Popover>
                </Badge>
              </div>
            </>
          )}
            <Button
              type="primary"
              className="postAJobBtn"
              onClick={() => navigate("/jobs")}
            >
              {t("common.Post a job")}
            </Button>
            <Button
              type="primary"
              className="howItWorksBtn"
              onClick={() => navigate("/howitworks")}
            >
              {t("common.How Does It Works")}
            </Button>
            <ConfigProvider
            theme={{
              token: {
                borderRadius: 5,
                controlHeight: 31,
                colorBorder: "rgb(96, 51, 29)",
              },
            }}
            >
            <Select
              className="selector_inputs"
              placeholder={t("common.language")}
              options={Languages?.map((el) => ({
                label: el?.label,
                value: el?.value,
              }))}
              onChange={handleLanguage}
            />
            </ConfigProvider>
            {!info && (
              <Button
                className="log-btn"
                type="primary"
                style={{ margin: "10px" }}
                onClick={() => navigate("/login")}
              >
                {t("common.Login")}
              </Button>
            )}
            {info && (
              <>
                <div className="drawer-hide">
                  {info?.role === ROLES?.CLIENT ? (
                    <ClientDropDown />
                  ) : (
                    <ProfessionalDropdown />
                  )}
                </div>
              </>
            )}
            <div className="navbar_drawer">{<ResponsiveDrawer />}</div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
