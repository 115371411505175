import HomeScreen from "components/home/homeScreen";
import { defer } from "react-router-dom";
import { getProfessionalData } from "services/getData";

export async function loader() {
  const data = await getProfessionalData();
  try {
    return defer({
      homeListing: data,
    });
  } catch (error) {
    console.log("error", error);
    throw new Error("Failed to load data");
  }
}
const Home = () => {
  return (
    <>
      <div>
        <HomeScreen />
      </div>
    </>
  );
};

export default Home;
