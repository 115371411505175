import { db } from "config";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useNotification(path) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.authUsers);
  const uid = userInfo?.userId;
  const getData = async () => {
    setLoading(true);
    const docRef = collection(db, path);
    const q = query(docRef, where("userId", "==", `${uid}`));
    const unSub = onSnapshot(q, async (snap) => {
      let arr = [];
      if (!snap.empty) {
        setData([]);
        const allPromises = snap.docs.map(async (doc) => {
          const data = doc.data();
          arr.push(data);
        });
        await Promise.all(allPromises)
          .then(() => {
            // console.log("task done");
            setData(arr);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
    return () => unSub();
  };
  useEffect(() => {
    getData(); // eslint-disable-next-line
  }, [path]);
  return { data, loading };
}

export default useNotification;
